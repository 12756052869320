import { Box, Grid, GridItem } from '@chakra-ui/react';
import React, { useState } from 'react';
import BookingInput from '../molecules/BookingInput';
import BookingInputBillOfLading from '../molecules/BookingInputBillOfLading';
import BookingInputHeader from '../molecules/BookingInputHeader';
import BookingInputTransportPlan from '../molecules/BookingInputTransportPlan';
import BookingInputEquipment from '../molecules/BookingInputEquipment';
import FlexTabList, { FlexTabListInput } from '../atoms/FlexTabList';
import BookingInputButtonGroup from '../molecules/BookingInputButtonGroup';
import Booking from '../../utils/Booking';
import { BookingRegistration } from '../../utils/types';
import { useUserApp } from '../../hooks/useUserApp';

export interface Props {
  tab: string;
  bookingInfo: Booking;
  bookingRegistrationInfo?: BookingRegistration | undefined;
}

function BookingInputForm({
  tab,
  bookingInfo,
  bookingRegistrationInfo,
}: Props) {
  const [selectedValue, setSelectedValue] = useState('booking');
  const [bookingInput, setBookingInput] = useState<Booking>(
    new Booking(JSON.parse(JSON.stringify(bookingInfo.data))),
  );
  const [bookingRegistrationInput, setBookingRegistrationInput] = useState<
    BookingRegistration | undefined
  >(
    bookingRegistrationInfo
      ? JSON.parse(JSON.stringify(bookingRegistrationInfo))
      : undefined,
  );
  console.log(bookingInfo.data);
  const userApp = useUserApp(bookingInfo.data.ownerId);

  const tabList = [
    {
      label: 'Booking',
      value: 'booking',
      component: (
        <BookingInput
          bookingInput={bookingInput}
          setBookingInput={(data: Booking) => {
            setBookingInput(data);
          }}
        />
      ),
    },
    {
      label: 'Transport',
      value: 'transport',
      component: (
        <BookingInputTransportPlan
          bookingInput={bookingInput}
          setBookingInput={(data: Booking) => {
            setBookingInput(data);
          }}
        />
      ),
    },
    {
      label: 'B/L',
      value: 'bl',
      component: (
        <BookingInputBillOfLading
          bookingInput={bookingInput}
          setBookingInput={(data: Booking) => {
            setBookingInput(data);
          }}
        />
      ),
    },
    {
      label: 'Equipment',
      value: 'equipment',
      component: (
        <BookingInputEquipment
          bookingInput={bookingInput}
          setBookingInput={(data: Booking) => {
            setBookingInput(data);
          }}
        />
      ),
    },
  ];

  const contentList: any[] = [];
  tabList.forEach((tab: FlexTabListInput) => {
    contentList.push(
      <Box key={tab.value}>
        {selectedValue === tab.value ? tab.component : <></>}
      </Box>,
    );
  });

  return (
    <Grid
      templateAreas={`"header"
                      "submit"
                      "tab"
                      "content"`}
      gridTemplateRows={'120px 40px 52 1fr'}
      // gridTemplateColumns={ appContext.state.isMobile ? '0px 1fr' : '350px 1fr'}
    >
      <GridItem area='header' p={3}>
        <BookingInputHeader
          tab={tab}
          bookingInput={bookingInput}
          setBookingInput={(data: Booking) => {
            setBookingInput(data);
          }}
          userApp={userApp.data}
          bookingRegistrationInput={bookingRegistrationInput}
          setBookingRegistrationInput={setBookingRegistrationInput}
        />
      </GridItem>

      {bookingRegistrationInput === undefined ||
      bookingRegistrationInput?.status === 'waiting' ? (
        <>
          <GridItem area='submit' p={3}>
            <BookingInputButtonGroup
              tab={tab}
              bookingInfo={bookingInfo}
              bookingInput={bookingInput}
            />
          </GridItem>

          <GridItem area='tab' px={'18px'} pt={'12px'} mb={'-2px'}>
            <FlexTabList
              onSelectedTab={(t: string) => {
                setSelectedValue(t);
              }}
              selectedTab={selectedValue}
              input={tabList}
              isFilled={true}
            />
          </GridItem>

          <GridItem area='content' overflowY='auto'>
            <Box shadow={'base'} mx={'12px'} bg={'white'}>
              {contentList}
            </Box>
          </GridItem>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
}

export default BookingInputForm;
