import {
  Tab, TabList, Tabs, Wrap, Text, Spacer, Box,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { AppContext } from '../../utils/context'
import SelectWithLabel from './SelectWithLabel'
import React from 'react'
import FilledTab from './FilledTab'

export type FlexTabListInput = {
  label: string,
  value: string,
  component: any
}

export interface Props {
  onSelectedTab: (t: string) => void,
  selectedTab: string,
  input: Array<FlexTabListInput>,
  mobileLeftTopComponent?: JSX.Element,
  isFilled?: boolean,
}

const FlexTabList = ({
  onSelectedTab,
  selectedTab,
  input,
  mobileLeftTopComponent,
  isFilled = false
}: Props) => {
  const appContext = useContext(AppContext)

  const tabList: any[] = []
  input.forEach((tab: FlexTabListInput) => {
    tabList.push(
      isFilled ? 
      <Box key={tab.value}>
      <FilledTab
        text={tab.label}
        value={tab.value}
        onSelectedTab={() => onSelectedTab(tab.value)}
        selectedTab={selectedTab}
      />
      </Box>
      :
      <Tab key={tab.value} onClick={() => onSelectedTab(tab.value)}>
        <Text fontSize='xs'>{tab.label}</Text>
      </Tab>
    )
  })

  const mobileMenu = new Map()
  input.forEach((tab: FlexTabListInput) => {
    mobileMenu.set(tab.value, tab.label)
  })

  const num = input.findIndex((tab: FlexTabListInput) => { return tab.value === selectedTab })

  return (
    <Tabs flex='1' index={num}>
      { appContext.state.isMobile ?
      <Wrap>
        { mobileLeftTopComponent ? mobileLeftTopComponent : <></>}
        <Spacer/>
        <SelectWithLabel
          options={mobileMenu}
          onClick={(v)=>{ onSelectedTab(v)} }
          selectedValue={selectedTab}
        />
      </Wrap> : 
      <TabList>
        {tabList}
      </TabList>
      }
    </Tabs>
  )
}

export default FlexTabList
  