import { useAuth0 } from '@auth0/auth0-react';
import { Box, HStack, Select, Spinner } from '@chakra-ui/react';
import React, { useState } from 'react';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import useFetch from '../../utils/fetch';
import { BookingRegistration } from '../../utils/types';

export interface Props {
  bookingRegistrationInput: BookingRegistration
  setBookingRegistrationInput: (data: BookingRegistration) => void
}

function BookingInputRegistrationStatus({
  bookingRegistrationInput,
  setBookingRegistrationInput
}: Props) {
  const [isSending, setIsSending] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(bookingRegistrationInput.status)
  const [isSuccess, setIsSuccess] = useState<boolean|undefined>(undefined)
  const [errorText, setErrorText] = useState('')
  const { fetchPut } = useFetch()
  const { getAccessTokenSilently } = useAuth0()

  const submitStatusChange = async (status: string) => {
    setIsSending(true)
    setIsSuccess(undefined)
    fetchPut(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/registrations/' + bookingRegistrationInput.id,
      {
        "carrierBookingId": bookingRegistrationInput.carrierBookingId,
        "carrierCode": bookingRegistrationInput.carrierCode,
        "comment": "",
        "status": status
      },
      (res: Object) => {
        setErrorText("")
        setIsSending(false)
        setIsSuccess(true)
        const br = {...bookingRegistrationInput}
        br.status = status
        setBookingRegistrationInput(br)
      },
      (res: Object) => {
        setErrorText(JSON.stringify(res))
        setIsSending(false)
        setIsSuccess(false)
      }
    )
  }

  return (
    <>
      <HStack spacing='12px'>
        <Select
        size='xs'
        w={'200px'}
        value={selectedStatus}
        onChange={(e) => {
          setSelectedStatus(e.target.value)
          submitStatusChange(e.target.value)
        }}>
          <option value='waiting'>-</option>
          <option value='suspended'>Suspended</option>
          <option value='invalid'>Invalid</option>
        </Select>
        { isSending ? 
        <Spinner size='xs' />
        :
        isSuccess === true ? 
        <RiCheckLine color='green'/>
        :
        isSuccess === false ?
        <RiCloseLine color='red'/>
        :
        <></>
        }
      </HStack>
      <Box color='red' fontSize={'xs'} my={'12px'}>
        {errorText}
      </Box>
    </>
  )
}

export default BookingInputRegistrationStatus
