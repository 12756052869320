import {
  Button,
} from '@chakra-ui/react'
import React from 'react'

export interface Props {
  label: string
  icon?: any
  size?: string
  fontWeight?: number
  isLoading?: boolean
  onClick?: () => void
}

const AccentLinkButton = ({ 
  label, 
  icon, 
  size,
  fontWeight,
  isLoading,
  onClick,
}: Props) => {

  return (
    <Button 
      leftIcon={icon} 
      size={size ? size : 'sm'}
      variant='link'
      colorScheme='cyan'
      fontWeight={fontWeight}
      isLoading={isLoading}
      onClick={onClick ? onClick : () => {}}
    >{label}</Button>
  )
}

export default AccentLinkButton
