import {
  Box,
  Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react'
import React from 'react'

export type tableColumnDef = {
  name: string
  header: JSX.Element
  cell: (row: any) => JSX.Element
}

export interface Props {
  tableData: any[]
  tableColumn: (any & tableColumnDef)[]
  onClickRow?: (row: any) => void
  padding?: string
  headerBgColor?: string
  fixedHeader?: boolean
  height?: string
}

const ListTable = ({
  tableData,
  tableColumn,
  onClickRow,
  padding,
  headerBgColor,
  fixedHeader = false,
  height,
}: Props) => {


  return (
    <Box height={height} overflow={height !== undefined ? "scroll" : ""}>
    <Table>
      <Thead style={ fixedHeader ? {
          "position": "sticky",
          "top": "0px"
        } : {}}>
        <Tr bg={headerBgColor}>
          {tableColumn.map( (v, k) => (
            <Th key={k} p={padding}>
              <>{v.header}</>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {tableData.map( (row, id) => (
          <Tr
            _hover={ {'backgroundColor' : '#F7FAFC', 'cursor': 'pointer'} }
            key={'parent-row-' + id}
          >
            {tableColumn.map( (col) => (
              <Td key={id + "-" + col.name} p={padding} onClick={onClickRow ? () => {onClickRow(row)} : ()=>{}}>
                <>{col.cell(row)}</>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
    </Box>
  )
}

export default ListTable
