import { useAuth0 } from '@auth0/auth0-react';
import { Box, HStack, Select, Spinner } from '@chakra-ui/react';
import React, { useState } from 'react';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import Booking from '../../utils/Booking';
import useFetch from '../../utils/fetch';

export interface Props {
  bookingInput: Booking
  setBookingInput: (data: Booking) => void
}

function BookingInputBookingStatus({ bookingInput, setBookingInput }: Props) {
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean|undefined>(undefined)
  const [selectedStatus, setSelectedStatus] = useState(bookingInput.data.operationStatus)
  const [errorText, setErrorText] = useState('')
  const { fetchPut } = useFetch()
  const { getAccessTokenSilently } = useAuth0()

  const submitStatusChange = async (status: string) => {
    setIsSending(true)
    setIsSuccess(undefined)
    fetchPut(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/bookings/status',
      [
        {
          "bookingId": bookingInput.data.id,
          "operationStatus": status
        }
      ],
      (res: Object) => {
        setErrorText("")
        setIsSending(false)
        setIsSuccess(true)
      },
      (res: Object) => {
        setErrorText(JSON.stringify(res))
        setIsSending(false)
        setIsSuccess(false)
      }
    )
  }

  return (
    <>
      <HStack spacing='12px'>
        <Select
          size='xs'
          w={'200px'}
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value)
            submitStatusChange(e.target.value)
        }}>
          <option value='tracking'>Tracking</option>
          <option value='non-tracking'>Non-Tracking</option>
        </Select>
        { isSending ? 
        <Spinner size='xs' />
        :
        isSuccess === true ? 
        <RiCheckLine color='green'/>
        :
        isSuccess === false ?
        <RiCloseLine color='red'/>
        :
        <></>
        }
      </HStack>
      <Box color='red' fontSize={'xs'} my={'12px'}>
        {errorText}
      </Box>
    </>
  )
}

export default BookingInputBookingStatus
