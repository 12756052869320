import {
  Text,
  Box,
  Flex,
  Spacer,
  useDisclosure,
  Center,
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import type {  UserInfo } from '../../utils/types'
import ListTable from '../atoms/ListTable'
import { useNavigate } from 'react-router-dom'
import Loading from '../Loading'
import { AppContext } from '../../utils/context'
import useDateConverter from '../../utils/DateConverter'
import PrimaryButton from '../atoms/PrimaryButton'
import ModalPlain from '../atoms/ModalPlain'
import UserInputForm from '../molecules/UserInputForm'
import useFetch from '../../utils/fetch'
import { useAuth0 } from '@auth0/auth0-react'

export interface Props {
  userList: UserInfo[] | undefined
  account: string
  reload: () => {}
}

const UserList = ({
  userList,
  account,
  reload,
}: Props) => {
  const navigate = useNavigate()
  const { fetchPost } = useFetch()
  const { getAccessTokenSilently } = useAuth0()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const appContext = useContext(AppContext)
  const {
    ToLocalYearDateTime
  } = useDateConverter()

  const [isSending, setIsSending] = useState<boolean>(false)
  const [initPassword, setInitPassword] = useState<string>("")
  
  const generateNewUser = () => {
    return {
      id: "",
      email: "",
      familyName: "",
      givenName: "",
      lastLogin: "",
      picture: "",
      createdAt: "",
      updatedAt: "",
      userMetadata: {
        organization: "",
        phone: "",
      },
      appMetadata: {
        note: "",
      },
    }
  }

  const [newUser, setNewUser] = useState<UserInfo>(generateNewUser())

  const generateInitPassword = () => {
    const password = require('secure-random-password');
    const pass = password.randomPassword({
      length: 10, 
      characters: [password.lower, password.upper, password.digits, password.symbols]
    })
    setInitPassword(pass)
    setNewUser(generateNewUser())
  }

  const onClickRow = (user: UserInfo) => {
    navigate("/managements/user/" + account + "/" + user.id)
  }

  const onSubmit = async (data: UserInfo, pass: string) => {
    setIsSending(true)
    fetchPost(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/users/' + ((account === "app") ? "app/" : "") + data.id,
      {
        "email": data.email,
        "password": pass,
        "appMetadata": data.appMetadata,
        "familyName": data.familyName,
        "givenName": data.givenName,
        "userMetadata": data.userMetadata,
      },
      (data) => {
        onClose()
        setIsSending(false)
        reload()
      },
      (error) => {
        setIsSending(false)
        // FIXME: Error handling
      }
    )
  }

  const columns = [
    {
      name: 'family-name',
      header: <Text textAlign={'left'}>Family Name</Text>,
      cell: (row: UserInfo) => {
        return <Text textAlign={'left'} fontSize={'sm'}>
          {row.familyName}
        </Text>
      }
    },
    {
      name: 'given-name',
      header: <Text textAlign={'left'}>Given Name</Text>,
      cell: (row: UserInfo) => {
        return <Text textAlign={'left'} fontSize={'sm'}>
          {row.givenName}
        </Text>
      }
    },
    {
      name: 'organization',
      header: <Text textAlign={'left'}>Organization</Text>,
      cell: (row: UserInfo) => {
        return <Text textAlign={'left'} fontSize={'sm'}>
          {row.userMetadata?.organization || ""}
        </Text>
      }
    },
    {
      name: 'email',
      header: <Text textAlign={'left'}>Email</Text>,
      cell: (row: UserInfo) => {
        return <Text textAlign={'left'} fontSize={'sm'}>
          {row.email}
        </Text>
      }
    },
    {
      name: 'phone',
      header: <Text textAlign={'left'}>Phone</Text>,
      cell: (row: UserInfo) => {
        return <Text textAlign={'left'} fontSize={'sm'}>
          {row.userMetadata?.phone || ""}
        </Text>
      }
    },
    {
      name: 'note',
      header: <Text textAlign={'left'}>Note</Text>,
      cell: (row: UserInfo) => {
        return <Text textAlign={'left'} fontSize={'sm'}>
          {row.appMetadata?.note || ""}
        </Text>
      }
    },
    {
      name: 'created-at',
      header: <Text textAlign={'center'}>CreatedTime</Text>,
      cell: (row: UserInfo) => {
        return <Text textAlign={'center'} fontSize={'sm'}>
          {ToLocalYearDateTime(row.createdAt)}
        </Text>
      }
    }
  ]

  return (
    <Box m={ appContext.state.isMobile ? "3px" : "15px"} boxShadow="base" bg='white'>
      {userList === undefined ? <Loading/> : 
      <Box overflowX={'auto'}>
        <Flex p={"12px"}>
          <Spacer/>
          <PrimaryButton label={'New User'} onClick={()=>{
            generateInitPassword()
            onOpen()
          }}/>
        </Flex>
        
        <ListTable
          tableData={userList}
          tableColumn={columns}
          padding='12px'
          headerBgColor='gray.200'
          onClickRow={onClickRow}
        />

      <ModalPlain
        isOpen={isOpen}
        onClose={onClose}
        title={'New User'}
        size={'3xl'}
      >
        <>
        <UserInputForm
          userInput={newUser}
          setUserInput={setNewUser}
          disabled={isSending}
          initPassword={initPassword}
        />
        <Center>
          <PrimaryButton
          label='Submit'
          size={"sm"}
          disabled={isSending}
          onClick={() => onSubmit(newUser, initPassword)}
        />
        </Center>
        </>
      </ModalPlain>
      </Box>
      
      }
    </Box>
  )
}

export default UserList
