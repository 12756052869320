import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody
} from '@chakra-ui/react'
import React from 'react'
import SideMainMenu from './SideMainMenu'

export interface Props {
  menuOpen: boolean,
  onClose: () => void,
}

const SideMainMenuDrawer = ({ menuOpen, onClose }: any) => {

  return (
    <Drawer
      isOpen={menuOpen}
      placement='left'
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bg='gray.200' >
        <DrawerBody>
          <SideMainMenu onClose={onClose}/>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default SideMainMenuDrawer
