import { useAuth0 } from '@auth0/auth0-react'
import {
  Table,
  Tbody,
  Tr,
  Td,
  Th,
} from "@chakra-ui/react"
import React from 'react'

const UserProfileComponent = () => {
  const { user } = useAuth0()

  if (user) {
    return (
      <Table variant='simple'>
        <Tbody>
          <Tr>
            <Th>Display Name</Th>
            <Td>{user.nickname}</Td>
          </Tr>
          <Tr>
            <Th>Email</Th>
            <Td>{user.email}</Td>
          </Tr>
        </Tbody>
      </Table>
    )
  }
  
  return <></>
}

export default UserProfileComponent
