import moment from "moment"

function useDateConverter() {
  const ToYearDate = (value: string) => {
    return value.split("T")[0]
  }

  const ToDate = (value: string) => {
    return value.split("T")[0].substring(5).replace(/-/gi, "/")
  }

  const ToYearDateTime = (value: string) => {
    return value.replace("T", " ").substring(0, 16)
  }

  const ToLocalYearDateTime = (value: string) => {
    return moment(value).local().format('YYYY-MM-DD HH:mm')
    // return value.replace("T", " ").substring(0, 16)
  }

  const IgnoreTimeZone = (value: string) => {
    return value.substring(0, 16)
  }

  return {
    ToLocalYearDateTime,
    ToYearDateTime,
    ToYearDate,
    ToDate,
    IgnoreTimeZone,
  }
}

export default useDateConverter
