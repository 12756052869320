import {
  Box, Button, Center, Flex, IconButton, Spacer,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import type {  UserInfo } from '../../utils/types'
import Loading from '../Loading'
import { AppContext } from '../../utils/context'
import UserInputForm from '../molecules/UserInputForm'
import PrimaryButton from '../atoms/PrimaryButton'
import { MdClose, MdEdit } from 'react-icons/md'
import useFetch from '../../utils/fetch'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'


export interface Props {
  userInfo: UserInfo | undefined
  account: string
}

const UserDetail = ({
  userInfo,
  account,
}: Props) => {
  const appContext = useContext(AppContext)
  const [userInput, setUserInput] = useState<UserInfo|undefined>()
  const [isViewMode, setIsViewMode] = useState<boolean>(true)
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true)

  const { fetchPut, fetchDelete } = useFetch()
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()

  const onUpdate = async (data: UserInfo) => {
    setIsFormDisabled(true)
    fetchPut(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/users/' + ((account === "app") ? "app/" : "") + data.id,
      {
        "appMetadata": data.appMetadata,
        "familyName": data.familyName,
        "givenName": data.givenName,
        "userMetadata": data.userMetadata,
      },
      (data) => {
        navigate("/managements/user/" + ((account === "app") ? "app" : "backoffice"))
      },
      (error) => {
        setIsFormDisabled(false)
        // FIXME: Error handling
      }
    )
  }

  const onDelete = async (userId: string) => {
    setIsFormDisabled(true)
    fetchDelete(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/users/' + ((account === "app") ? "app/" : "") + userId,
      {},
      (data) => {
        navigate("/managements/user/" + ((account === "app") ? "app" : "backoffice"))
      },
      (error) => {
        setIsFormDisabled(false)
        // FIXME: Error handling
      }
    )
  }

  const InitUserInput = () => {
    setUserInput(userInfo === undefined ? undefined : JSON.parse(JSON.stringify(userInfo)))
  }

  useEffect(() => {
    InitUserInput()
  }, [userInfo]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m={ appContext.state.isMobile ? "3px" : "15px"} boxShadow="base" bg='white'>
      {userInput === undefined ? <Loading/> : 
      <Box overflowX={'auto'}>
        <Flex p={"12px"}>
          <Spacer/>
          {isViewMode ?
          <IconButton
            size={"sm"}
            mx={"12px"}
            icon={<MdEdit />}
            aria-label={'edit'}
            onClick={() => {
              setIsViewMode(!isViewMode)
              setIsFormDisabled(false)
            }}
          />
          :
          <IconButton
            size={"sm"}
            mx={"12px"}
            icon={<MdClose />}
            aria-label={'close'}
            onClick={() => {
              setIsViewMode(!isViewMode)
              setIsFormDisabled(true)
              InitUserInput()
            }}
          />
          }
        </Flex>
        <UserInputForm 
            userInput={userInput}
            setUserInput={setUserInput}
            disabled={isFormDisabled}
        />

        { isViewMode ? 
        <></>
        :
        <Center p={"12px"}>
          <Button
            size={"sm"}
            mx={"12px"}
            disabled={isFormDisabled}
            onClick={() => {
              InitUserInput()
              setIsViewMode(true)
              setIsFormDisabled(true)
            }}
          >Cancel</Button>
          <PrimaryButton label='Update' onClick={() => {onUpdate(userInput)}} disabled={isFormDisabled}/>
          <Button
          size={"sm"}
          mx={"12px"}
          colorScheme={"red"}
          disabled={isFormDisabled}
          onClick={() => {onDelete(userInput.id)}}
          >Delete</Button>
        </Center>
        }
      </Box>
      }
    </Box>
  )
}

export default UserDetail

