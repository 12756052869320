import { Box, Text, FormControl, FormLabel, Input, SimpleGrid, Flex, Spacer, CloseButton, Center, Button, Divider, Select } from '@chakra-ui/react';
import React from 'react';
// @ts-ignore 
import Booking from '../../utils/Booking';
import { Commodity } from '../../utils/types';
import FlatCard from '../atoms/FlatCard';
import SelectButtonGroup from '../atoms/SelectButtonGroup';


const incotermsTypes = new Map([
  ["EXW", "Ex Works"],
  ["FCA", "Free Carrier"],
  ["CPT", "Carriage Paid To"],
  ["CIP", "Carriage and Insurance Paid To"],
  ["DAF", "Delivered at Frontier"],
  ["DES", "Delivered at Ex Ship"],
  ["DEQ", "Delivered at Ex Quany"],
  ["DDU", "Delivered Duty Unpaid"],
  ["DAT", "Delivered at Terminal"],
  ["DAP", "Delivered at Place"],
  ["DPU", "Delivered at Place Unloaded"],
  ["DDP", "Delivered Duty Paid"],
  ["FAS", "Free alongside Ship"],
  ["FOB", "Free on Board"],
  ["CFR", "Cost and Freight"],
  ["CIF", "Cost Insurance and Freight"],
])

export interface Props {
  bookingInput: Booking
  setBookingInput: (data: Booking) => void
}

function BookingInputBillOfLading({
  bookingInput,
  setBookingInput,
}: Props) {
  const fontSize = "sm"
  const marginBottom = "12px"

  const addInputCommodity = () => {
    bookingInput.addCommodity()
    setBookingInput(new Booking(bookingInput.data))
  }

  const removeInputCommodity = (index: number)=>{
    bookingInput.deleteCommodity(index)
    setBookingInput(new Booking(bookingInput.data))
  }

  const handleChangeCommodity = (index: number, data: Commodity)=>{
    bookingInput.updateCommodity(index, data)
    setBookingInput(new Booking(bookingInput.data))
  }

  let itemList: any = []
  incotermsTypes.forEach((value, key)=>{
    itemList.push( 
      <option key={key} value={key}>{key} ({value})</option>
    )
  })

  return (
    <Box p={"12px"}>
      <SimpleGrid columns={3} spacing={10} m={5}>
        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>Bill of Lading No</FormLabel>
          <Input size={"sm"} type='text' value={bookingInput.data.billOfLading || ""} onChange={(event: any) => {
            bookingInput.data.billOfLading = event.target.value.length === 0 ? null : event.target.value
            setBookingInput(new Booking(bookingInput.data))
          }}/>
        </FormControl>

        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>Incoterms</FormLabel>
          <Select
            placeholder='Select Incoterms'
            size='sm'
            value={bookingInput.data.incoterms || ""}
            onChange={(e) => {
              bookingInput.data.incoterms = e.target.value.length === 0 ? null : e.target.value
              setBookingInput(new Booking(bookingInput.data))
            }}
          >
            {itemList}
          </Select>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={3} spacing={10} m={5}>
        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>Primary Mode</FormLabel>
          <SelectButtonGroup
            options={new Map([
              ["none", "非表示"],
              ["ocean", "Ocean"],
            ])}
            selectedValue={bookingInput.data.primaryTransport || "none"}
            onClick={(v: string) => {
              bookingInput.data.primaryTransport = v === "none" ? null : v
              setBookingInput(new Booking(bookingInput.data))
            }}
          />
        </FormControl>

        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>Mode Type</FormLabel>
          <SelectButtonGroup
            options={new Map([
              ["none", "非表示"],
              ["FCL", "FCL"],
              ["LCL", "LCL"],
            ])}
            selectedValue={bookingInput.data.loadType || "none"}
            onClick={(v: string) => {
              bookingInput.data.loadType = v === "none" ? null : v
              setBookingInput(new Booking(bookingInput.data)) 
            }}
          />
        </FormControl>
      </SimpleGrid>

    { bookingInput.data.commodities?.map( (data: Commodity, index: number ) => {

      return (
        <Box p={"12px"} key={index}>
          <FlatCard>
          <Flex>
            <Text fontSize={"sm"} color={"gray.400"}>Commodity No. {index + 1}</Text>
            <Spacer/>
            <CloseButton size={"sm"} onClick={() => {removeInputCommodity(index)}}/>
          </Flex>
          <Divider/>
          <Box p={"12px"} key={index}>
            <SimpleGrid columns={3} spacing={10}>
              <FormControl isRequired mb={marginBottom}>
                <FormLabel fontSize={fontSize}>Commodity Name</FormLabel>
                <Input size={"sm"} type='text' value={data.description} onChange={(event: any) => {
                  data.description = event.target.value
                  handleChangeCommodity(index, data)
                  }}/>
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Quantity</FormLabel>
                  <Input size={"sm"} type='text' value={data.quantity || ""} onChange={(event: any) => {
                    data.quantity = event.target.value.length === 0 ? null : parseInt(event.target.value)
                    handleChangeCommodity(index, data)
                  }}/>
              </FormControl>

              <FormControl mb={marginBottom}>
                <FormLabel fontSize={fontSize}>Weight</FormLabel>
                <Input size={"sm"} type='text' value={data.weight || ""} onChange={(event: any) => {
                  data.weight = event.target.value.length === 0 ? null : event.target.value
                  handleChangeCommodity(index, data)
                }}/>
              </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={3} spacing={10}>
              <FormControl mb={marginBottom}>
                <FormLabel fontSize={fontSize}>Harmonized Code</FormLabel>
                <Input size={"sm"} type='text' value={data.harmonizedCode || ""} onChange={(event: any) => {
                  data.harmonizedCode = event.target.value.length === 0 ? null : event.target.value
                  handleChangeCommodity(index, data)
                }}/>
              </FormControl>
            </SimpleGrid>
          </Box>
          </FlatCard>
        </Box>
        )
        })}
        <Center>
        <Button
          size={"xs"}
          colorScheme="cyan"
          variant={"ghost"}
          onClick={addInputCommodity}
          m={5}
        >
          ＋ Add Commodity</Button>
      </Center>
      </Box>
    )
}

export default BookingInputBillOfLading
