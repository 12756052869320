import React from 'react'
import { Box, Button, ListItem } from '@chakra-ui/react';


export interface Props {
  label: string
  onClose: () => void
  
  size?: string
}

const MenuItem = ({ label, onClose }: Props) => {
  return (
    <ListItem>
      <Button 
        color="blue.800" 
        variant='ghost' 
        h={"44px"} 
        fontWeight={300}
        _hover={{ bg: "blackAlpha.50" }}
        _focus={{ bg: "blackAlpha.50" }}
        onClick={() => { onClose() }}
      >
        <Box w={"150px"}>{label}</Box>
      </Button>
    </ListItem>
  )
}

export default MenuItem
