import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../utils/fetch';
import { UserInfo } from '../../../../utils/types';
import UserList from '../../../organisms/UserList';

function UserManagementList() {
  const { fetchGet } = useFetch()
  const [userList, setUserList] = useState<Array<UserInfo>|undefined>(undefined)
  const { getAccessTokenSilently } = useAuth0()

  const { account } = useParams()

  const GetUserList = async () => {
    setUserList(undefined)
    fetchGet(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/users/' + ((account === "app") ? "app/" : ""),
      (data) => {
        setUserList(Object.values(data))
      },
      (error) => {
        // toast(errorToast)
      }
    )
  }

  useEffect(() => {
    GetUserList()
  }, [account]) // eslint-disable-line react-hooks/exhaustive-deps

  if (account === undefined) {
    return <p>Invalid URL</p>
  } 
  return (
    <UserList userList={userList} account={account} reload={GetUserList}/>
  )
}

export default UserManagementList
