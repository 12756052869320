import { createContext } from "react"

export type AppContextValue = {
  state: {
    isMobile: boolean | undefined
  }
}

export const AppContext = createContext<AppContextValue>({
  'state' : {
    'isMobile': undefined,
  }
});
