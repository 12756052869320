import { useEffect, useState } from 'react';
import useFetch from '../utils/fetch';
import { useAuth0 } from '@auth0/auth0-react';

export type UserApp = {
  id: string;
  email: string;
  familyName: string;
  givenName: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  lastLogin: string;
  userMetadata?: {
    organization: string;
    phone: string;
  };
  appMetadata?: { note: string };
  picture: string;
};

export const useUserApp = (userId?: string) => {
  const [data, setData] = useState<UserApp | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchGet } = useFetch();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    void (async () => {
      console.log(userId);
      if (!userId) return;
      setIsLoading(true);
      fetchGet(
        await getAccessTokenSilently(),
        `${process.env.REACT_APP_HARBITT_BACKEND_URL}/backoffice/v1/users/app/${userId}`,
        (data) => {
          setData(data as UserApp);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        },
      );
    })();
  }, [fetchGet, getAccessTokenSilently, userId]);

  return { data, isLoading };
};
