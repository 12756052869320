import { Box } from '@chakra-ui/react';
import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface Props {
  isClearable?: boolean
  selectedDateTime: Date | null
  onChange: (date: Date | null) => void
}

const DateTimePicker = ({ 
  isClearable = false,
  selectedDateTime, 
  onChange, 
}: Props) => {

  return (
    <Box fontSize={"sm"} className={"datetime-picker"}>
      <DatePicker
        isClearable={isClearable}
        selected={selectedDateTime}
        onChange={(date: Date | null) => onChange(date)}
        timeInputLabel="Time:"
        dateFormat="yyyy/MM/dd h:mm aa"
        showTimeInput
        shouldCloseOnSelect={false}
      />
    </Box>
  )
}

export default DateTimePicker
