import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import React, { useState } from 'react';
// @ts-ignore 
import Booking from '../../utils/Booking';
import useDateConverter from '../../utils/DateConverter';
import useFetch from '../../utils/fetch';
import { BookingScheduleHistoryResponse } from '../../utils/types';
import DateTimePicker from '../atoms/DateTimePicker';
import ModalPlain from '../atoms/ModalPlain';
import Loading from '../Loading';
// @ts-ignore
import HistoryList from './HistoryList';
import { 
  MdHistory
} from "react-icons/md"

export interface Props {
  bookingInput: Booking
  setBookingInput: (data: Booking) => void
}

function BookingInput({ bookingInput, setBookingInput }: Props) {
  const fontSize = "sm"
  const marginBottom = "12px"
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { fetchGet } = useFetch()
  const { getAccessTokenSilently } = useAuth0()
  const [history, setHistory] = useState<Array<BookingScheduleHistoryResponse>|undefined>(undefined)
  const [modalTitle, setModalTitle] = useState('')
  
  const {
    IgnoreTimeZone,
  } = useDateConverter()

  const getBookingScheduleHistory = async (scheduleType: string) => {
    setHistory(undefined)
    fetchGet(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/bookings/' + bookingInput.data.id + '/histories?type=' + scheduleType,
      (data) => {
        setHistory(data as BookingScheduleHistoryResponse[])
      },
      (error) => {
        setHistory(undefined)
        // FIXME: Error handling
      }
    )
  }


  const onClickHistory = (scheduleType: string, displayName: string) => {
    setModalTitle(displayName)
    getBookingScheduleHistory(scheduleType)
    onOpen()
  }

  return (
    <Box p={"12px"} >
      <SimpleGrid columns={3} spacing={10} m={5}>
        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>POL</FormLabel>
          <InputGroup>
            <Input size={"sm"} type='text' value={bookingInput.data.portOfLoading?.code} disabled={true}/>
            <InputRightElement>
              <Button
              variant="outline"
              size={"xs"}
              colorScheme={'cyan'}
              as={IconButton}
              icon={<MdHistory />}
              onClick={() => {onClickHistory('pol', 'Port Of Loading')}}
              />
            </InputRightElement>
          </InputGroup>
       </FormControl>

        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>POD</FormLabel>
          <InputGroup>
            <Input size={"sm"} type='text' value={bookingInput.data.portOfDischarge?.code} disabled={true}/>
            <InputRightElement>
              <Button
              variant="outline"
              size={"xs"}
              colorScheme={'cyan'}
              as={IconButton}
              icon={<MdHistory />}
              onClick={() => {onClickHistory('pod', 'Port Of Discharge')}}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Last Tranship</FormLabel>
          <Input size={"sm"} type='text' value={bookingInput.getLastTransit()?.code || "(Direct)"} disabled={true}/>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={3} spacing={10} m={5}>
        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>Trade Type</FormLabel>
          <Input size={"sm"} type='text' value={bookingInput.getTradeType("JP")} disabled={true}/>
        </FormControl>
        <></>
        <></>
      </SimpleGrid>

      <SimpleGrid columns={3} spacing={10} m={5}>
        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Place of Receipt</FormLabel>
          <InputGroup>
            <Input size={"sm"} type='text' value={bookingInput.getPlaceOfReceiptTransport()?.departurePort.city || ""} disabled={true}/>
            <InputRightElement>
              <Button
              variant="outline"
              size={"xs"}
              colorScheme={'cyan'}
              as={IconButton}
              icon={<MdHistory />}
              onClick={() => {onClickHistory('receipt', 'Place Of Receipt')}}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Destination</FormLabel>
          <InputGroup>
            <Input size={"sm"} type='text' value={bookingInput.getDestinationTransport()?.arrivalPort.city || ""} disabled={true}/>
            <InputRightElement>
              <Button
              variant="outline"
              size={"xs"}
              colorScheme={'cyan'}       
              as={IconButton}
              icon={<MdHistory />}
              onClick={() => {onClickHistory('destination', 'Destination')}}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={3} spacing={10} m={5}>
        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Number of Containers</FormLabel>
          <Input size={"sm"} type='text' value={bookingInput.data.transportEquipments?.length} disabled={true}/>
        </FormControl>
        <></>
        <></>
      </SimpleGrid>

      <SimpleGrid columns={3} spacing={10} m={5}>
        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>plannedVgmCutOff</FormLabel>
          <DateTimePicker
            selectedDateTime={bookingInput.data.plannedVgmCutOff ? new Date(IgnoreTimeZone(bookingInput.data.plannedVgmCutOff)) : null}
            onChange={(date: Date | null) => {
              if (date === null) {
                bookingInput.data.plannedVgmCutOff = null
              }
              bookingInput.data.plannedVgmCutOff = IgnoreTimeZone(moment(date).format()) + ":00Z"
              setBookingInput(new Booking(bookingInput.data))
            }}
          />
        </FormControl>

        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>plannedPortCutOff</FormLabel>
          <DateTimePicker
            selectedDateTime={bookingInput.data.plannedPortCutOff ? new Date(bookingInput.data.plannedPortCutOff) : null}
            onChange={(date: Date | null) => {
              if (date === null) {
                bookingInput.data.plannedPortCutOff = null
              } else {
                bookingInput.data.plannedPortCutOff = IgnoreTimeZone(moment(date).format()) + ":00Z"
              }
              setBookingInput(new Booking(bookingInput.data))
            }}
          />
        </FormControl>
      </SimpleGrid>

      <ModalPlain
        isOpen={isOpen}
        onClose={onClose}
        title={modalTitle + ' - History'}
        size={'3xl'}
      >
        {
        history === undefined ?
        <Loading/>
        :
        <HistoryList
          historyData={history}
          setHistoryData={setHistory}
          bookingId={bookingInput.data.id}
        />
        }
      </ModalPlain>
    </Box>
  )
}

export default BookingInput
