import {
  Button,
} from '@chakra-ui/react'
import React from 'react'

export interface Props {
  label: string
  icon?: any
  size?: string
  disabled?: boolean
  isLoading?: boolean
  variant?: string
  onClick?: () => void
  width?: string
}

const PrimaryButton = ({ 
  label, 
  icon, 
  size, 
  disabled,
  isLoading,
  variant,
  onClick,
  width,
}: Props) => {

  return (
    <Button 
      leftIcon={icon} 
      size={size ? size : 'sm'}
      colorScheme='blue'
      onClick={onClick ? onClick : () => {}}
      disabled={disabled}
      isLoading={isLoading}
      variant={variant}
      width={width}
      fontWeight={400}
    >{label}</Button>
  )
}

export default PrimaryButton
