import {
  BookingInfo,
  BookingRegistration,
  BookingScheduleHistory,
  Commodity,
  RequestedShipment,
  ScheduleChangeReason,
  TransportEquipment,
} from './types';

export default class Booking {
  data: BookingInfo;

  constructor(
    data: BookingInfo = {
      id: '',
      carrierBookingId: '',
      carrierCode: '',
      billOfLading: null,
      incoterms: null,
      primaryTransport: null,
      loadType: null,
      plannedDepartureTime: null,
      actualDepartureTime: null,
      plannedArrivalTime: null,
      actualArrivalTime: null,
      portOfLoading: {
        code: '',
        city: '',
        country: '',
      },
      portOfDischarge: {
        code: '',
        city: '',
        country: '',
      },
      plannedPortCutOff: null,
      plannedVgmCutOff: null,
      isBookmarked: false,
      status: 'ongoing',
      operationStatus: 'tracking',
      name: null,
      transportEquipments: [],
      requestedShipments: [],
      commodities: [],
      createdAt: '',
      updatedAt: '',
      ownerId: '',
    },
  ) {
    this.data = data;
  }

  convertToShipmentInput() {
    if (this.data.requestedShipments === undefined) {
      return;
    }

    const shipments: Array<RequestedShipment & ScheduleChangeReason> = [];

    this.data.requestedShipments.forEach((value) => {
      shipments.push({
        ...value,
        plannedDepartureChangeReason: null,
        actualDepartureChangeReason: null,
        plannedArrivalChangeReason: null,
        actualArrivalChangeReason: null,
      });
    });

    this.data.requestedShipments = shipments;
  }

  loadFromBookingData(data: BookingInfo) {
    this.data = data;
    this.convertToShipmentInput();
  }

  loadFromRegistrationData(data: BookingRegistration) {
    this.data.id = data.id;
    this.data.carrierBookingId = data.carrierBookingId;
    this.data.carrierCode = data.carrierCode;
    this.data.ownerId = data.ownerId;
    this.data.createdAt = data.createdAt;
    this.data.updatedAt = data.updatedAt;
  }

  addTransportPlan() {
    if (this.data.requestedShipments === undefined) {
      this.data.requestedShipments = [];
    }
    this.data.requestedShipments = [
      ...this.data.requestedShipments,
      {
        id: null,
        arrivalPort: {
          city: '',
          code: '',
          country: '',
        },
        departurePort: {
          city: '',
          code: '',
          country: '',
        },
        isOtherMethod: false,
        plannedArrivalTime: null,
        plannedArrivalChangeReason: null,
        actualArrivalTime: null,
        actualArrivalChangeReason: null,
        plannedDepartureTime: null,
        plannedDepartureChangeReason: null,
        actualDepartureTime: null,
        actualDepartureChangeReason: null,
        serviceCode: null,
        transportType: null,
        vesselName: null,
        transportOrder: this.data.requestedShipments.length + 1,
        voyageId: null,
        vesselCarrier: null,
      },
    ];
  }

  deleteTransportPlan(index: number) {
    const d = { ...this.data };
    d.requestedShipments.splice(index, 1);
    d.requestedShipments.forEach((_, i) => {
      d.requestedShipments[i].transportOrder = i + 1;
    });
    this.data = d;
  }

  moveTransportPlan(fromIndex: number, toIndex: number) {
    const d = { ...this.data };
    if (fromIndex < 0 || d.requestedShipments.length <= fromIndex) {
      return;
    }
    if (toIndex < 0 || d.requestedShipments.length <= toIndex) {
      return;
    }
    var element = d.requestedShipments[fromIndex];
    d.requestedShipments.splice(fromIndex, 1);
    d.requestedShipments.splice(toIndex, 0, element);
    d.requestedShipments.forEach((_, i) => {
      d.requestedShipments[i].transportOrder = i + 1;
    });
    this.data = d;
  }

  updateTransportPlan = (
    index: number,
    data: RequestedShipment & ScheduleChangeReason,
  ) => {
    const d = JSON.parse(JSON.stringify(this.data));
    d.requestedShipments[index] = data;
    this.data = d;

    const pod = this.getPODTransport();
    if (pod !== undefined) {
      this.data.portOfDischarge = pod.arrivalPort;
      this.data.plannedArrivalTime = pod.plannedArrivalTime;
      this.data.actualArrivalTime = pod.actualArrivalTime;
    }

    const pol = this.getPOLTransport();
    if (pol !== undefined) {
      this.data.portOfLoading = pol.departurePort;
      this.data.plannedDepartureTime = pol.plannedDepartureTime;
      this.data.actualDepartureTime = pol.actualDepartureTime;
    }
  };

  addEquipment() {
    if (this.data.transportEquipments === undefined) {
      this.data.transportEquipments = [];
    }
    this.data.transportEquipments = [
      ...this.data.transportEquipments,
      {
        id: null,
        isShipperOwn: false,
        isoCode: '',
        reference: null,
        sealId: null,
        pickUpAt: null,
        releaseAt: null,
        receiptAt: null,
        returnAt: null,
      },
    ];
  }

  deleteEquipment(index: number) {
    const d = { ...this.data };
    d.transportEquipments.splice(index, 1);
    this.data = d;
  }

  updateEquipment(index: number, data: TransportEquipment) {
    const d = { ...this.data };
    d.transportEquipments[index] = data;
    this.data = d;
  }

  addCommodity() {
    if (this.data.commodities === undefined) {
      this.data.commodities = [];
    }
    this.data.commodities = [
      ...this.data.commodities,
      {
        id: null,
        description: '',
        harmonizedCode: null,
        quantity: null,
        weight: null,
      },
    ];
  }

  deleteCommodity(index: number) {
    const d = { ...this.data };
    d.commodities.splice(index, 1);
    this.data = d;
  }

  updateCommodity = (index: number, data: Commodity) => {
    const d = { ...this.data };
    d.commodities[index] = data;
    this.data = d;
  };

  getPODTransport() {
    if (this.data.requestedShipments.length === 0) {
      return undefined;
    }
    const len = this.data.requestedShipments.length;
    const destCountry = this.data.requestedShipments[
      len - 1
    ].arrivalPort.code.substring(0, 2);
    const index: number = this.data.requestedShipments.findIndex((s) => {
      return s.arrivalPort.code.substring(0, 2) === destCountry;
    });
    return this.data.requestedShipments[index];
  }

  getPOLTransport() {
    if (this.data.requestedShipments.length === 0) {
      return undefined;
    }
    const originCountry =
      this.data.requestedShipments[0].departurePort.code.substring(0, 2);
    const tmp = [...this.data.requestedShipments].reverse();
    const index = tmp.findIndex((s) => {
      return s.departurePort.code.substring(0, 2) === originCountry;
    });
    return tmp[index];
  }

  getPlaceOfReceiptTransport() {
    if (this.data.requestedShipments.length === 0) {
      return undefined;
    }
    return this.data.requestedShipments[0];
  }

  getDestinationTransport() {
    const len = this.data.requestedShipments.length;
    if (len === 0) {
      return undefined;
    }
    return this.data.requestedShipments[len - 1];
  }

  getTradeType(location: string) {
    const len = this.data.requestedShipments.length;
    if (len === 0) {
      return '';
    }
    const originCountry =
      this.data.requestedShipments[0].departurePort.code.substring(0, 2);
    const destCountry = this.data.requestedShipments[
      len - 1
    ].arrivalPort.code.substring(0, 2);
    if (originCountry === location && destCountry === location) {
      return 'Invalid';
    }
    if (originCountry === location && destCountry !== location) {
      return 'Export';
    }
    if (originCountry !== location && destCountry === location) {
      return 'Import';
    }

    return 'Intermediary';
  }

  getLastTransit() {
    if (this.data.requestedShipments.length === 0) {
      return undefined;
    }
    const tmp = [...this.data.requestedShipments].filter((v) => {
      return !v.isOtherMethod;
    });
    if (tmp.length > 1) {
      return tmp[tmp.length - 1].departurePort;
    }
    return undefined;
  }

  getScheduleChange(booking: Booking) {
    const result: BookingScheduleHistory[] = [];

    // Receipt
    const newPoL = this.getPlaceOfReceiptTransport();
    const oldPoL = booking.getPlaceOfReceiptTransport();

    if (newPoL !== undefined) {
      // Receipt - ETD
      if (
        (newPoL.departurePort.code !== oldPoL?.departurePort.code ||
          newPoL.plannedDepartureTime !== oldPoL?.plannedDepartureTime) &&
        newPoL.plannedDepartureTime !== null &&
        newPoL.plannedDepartureTime !== undefined
      ) {
        result.push({
          id: null,
          datetime: newPoL.plannedDepartureTime,
          datetimeType: 'estimate',
          location: newPoL.departurePort,
          reason: newPoL.plannedDepartureChangeReason || null,
          scheduleType: 'receipt',
        });
      }

      // Receipt - ATD
      if (
        (newPoL.departurePort.code !== oldPoL?.departurePort.code ||
          newPoL.actualDepartureTime !== oldPoL?.actualDepartureTime) &&
        newPoL.actualDepartureTime !== null &&
        newPoL.actualDepartureTime !== undefined
      ) {
        result.push({
          id: null,
          datetime: newPoL.actualDepartureTime,
          datetimeType: 'actual',
          location: newPoL.departurePort,
          reason: newPoL.actualDepartureChangeReason || null,
          scheduleType: 'receipt',
        });
      }
    }

    // POL
    const newPOL = this.getPOLTransport();
    const oldPOL = booking.getPOLTransport();

    if (newPOL !== undefined) {
      // POL - ETD
      if (
        (newPOL.departurePort.code !== oldPOL?.departurePort.code ||
          newPOL.plannedDepartureTime !== oldPOL?.plannedDepartureTime) &&
        newPOL.plannedDepartureTime !== null &&
        newPOL.plannedDepartureTime !== undefined
      ) {
        result.push({
          id: null,
          datetime: newPOL.plannedDepartureTime,
          datetimeType: 'estimate',
          location: newPOL.departurePort,
          reason: newPOL.plannedDepartureChangeReason || null,
          scheduleType: 'pol',
        });
      }

      // POL - ATD
      if (
        (newPOL.departurePort.code !== oldPOL?.departurePort.code ||
          newPOL.actualDepartureTime !== oldPOL?.actualDepartureTime) &&
        newPOL.actualDepartureTime !== null &&
        newPOL.actualDepartureTime !== undefined
      ) {
        result.push({
          id: null,
          datetime: newPOL.actualDepartureTime,
          datetimeType: 'actual',
          location: newPOL.departurePort,
          reason: newPOL.actualDepartureChangeReason || null,
          scheduleType: 'pol',
        });
      }
    }

    // POD
    const newPOD = this.getPODTransport();
    const oldPOD = booking.getPODTransport();

    if (newPOD !== undefined) {
      // POD - ETA
      if (
        (newPOD.arrivalPort.code !== oldPOD?.arrivalPort.code ||
          newPOD.plannedArrivalTime !== oldPOD?.plannedArrivalTime) &&
        newPOD.plannedArrivalTime !== null &&
        newPOD.plannedArrivalTime !== undefined
      ) {
        result.push({
          id: null,
          datetime: newPOD.plannedArrivalTime,
          datetimeType: 'estimate',
          location: newPOD.arrivalPort,
          reason: newPOD.plannedArrivalChangeReason || null,
          scheduleType: 'pod',
        });
      }

      // POD - ATA
      if (
        (newPOD.arrivalPort.code !== oldPOD?.arrivalPort.code ||
          newPOD.actualArrivalTime !== oldPOD?.actualArrivalTime) &&
        newPOD.actualArrivalTime !== null &&
        newPOD.actualArrivalTime !== undefined
      ) {
        result.push({
          id: null,
          datetime: newPOD.actualArrivalTime,
          datetimeType: 'actual',
          location: newPOD.arrivalPort,
          reason: newPOD.actualArrivalChangeReason || null,
          scheduleType: 'pod',
        });
      }
    }

    // Destination
    const newDest = this.getDestinationTransport();
    const oldDest = booking.getDestinationTransport();

    if (newDest !== undefined) {
      // Destination - ETA
      if (
        (newDest.arrivalPort.code !== oldDest?.arrivalPort.code ||
          newDest.plannedArrivalTime !== oldDest?.plannedArrivalTime) &&
        newDest.plannedArrivalTime !== null &&
        newDest.plannedArrivalTime !== undefined
      ) {
        result.push({
          id: null,
          datetime: newDest.plannedArrivalTime,
          datetimeType: 'estimate',
          location: newDest.arrivalPort,
          reason: newDest.plannedArrivalChangeReason || null,
          scheduleType: 'destination',
        });
      }

      // Destination - ATA
      if (
        (newDest.arrivalPort.code !== oldDest?.arrivalPort.code ||
          newDest.actualArrivalTime !== oldDest?.actualArrivalTime) &&
        newDest.actualArrivalTime !== null &&
        newDest.actualArrivalTime !== undefined
      ) {
        result.push({
          id: null,
          datetime: newDest.actualArrivalTime,
          datetimeType: 'actual',
          location: newDest.arrivalPort,
          reason: newDest.actualArrivalChangeReason || null,
          scheduleType: 'destination',
        });
      }
    }

    return result;
  }

  validateRequest() {
    if (this.data.requestedShipments === undefined) {
      return 'Invalid Transport';
    }

    if (this.data.requestedShipments.length === 0) {
      return 'Empty Transport';
    }

    if (this.getPlaceOfReceiptTransport() === undefined) {
      return 'Invalid Place of Receipt';
    }

    if (this.getPOLTransport() === undefined) {
      return 'Invalid POL';
    }

    if (this.getPODTransport() === undefined) {
      return 'Invalid POD';
    }

    if (this.getDestinationTransport() === undefined) {
      return 'Invalid Destination';
    }

    return this.validateShipment();
  }

  validateShipment() {
    const invalid = this.data.requestedShipments.find((value) => {
      const valid =
        value.departurePort.code.length === 5 &&
        value.departurePort.city !== undefined &&
        value.departurePort.country !== undefined &&
        value.departurePort.city !== null &&
        value.departurePort.country !== null &&
        value.arrivalPort.code.length === 5 &&
        value.arrivalPort.city !== undefined &&
        value.arrivalPort.country !== undefined &&
        value.arrivalPort.city !== null &&
        value.arrivalPort.country !== null &&
        ((value.isOtherMethod === false &&
          value.transportType === null &&
          value.vesselName !== null &&
          value.voyageId !== null &&
          value.serviceCode !== null) ||
          (value.isOtherMethod === true && value.transportType !== null));
      return !valid;
    });

    if (invalid !== undefined) {
      return 'Invalid Transport (No. ' + invalid.transportOrder + ')';
    }

    return '';
  }
}
