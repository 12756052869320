import React from 'react'
import UserProfileComponent from '../../organisms/Profile'
import UserDebugComponent from '../../molecules/Debug'


const UserProfile = () => {
  return (
    <>
      <UserProfileComponent/>
      <UserDebugComponent/>
    </>
  )
}

export default UserProfile
