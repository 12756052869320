import {
  Tab, Text,
} from '@chakra-ui/react'
import React from 'react'

export interface Props {
  text: string,
  value: string,
  onSelectedTab: (t: string) => void,
  selectedTab: string,
}

const FilledTab = ({
  text,
  value,
  onSelectedTab,
  selectedTab,
}: any) => {
  const isSelected = selectedTab === value
  return (
    <Tab 
    backgroundColor={isSelected ? "white" : "gray.100"}
    onClick={() => onSelectedTab(value)}
    >
      <Text
      fontSize={'sm'}
      fontWeight={isSelected ? 500 : 300}
      color={isSelected ? "cyan.600" : "gray.500"}
      >{text}</Text>
    </Tab>
  )
}

export default FilledTab
  