import './App.css';
import { AppContext } from './utils/context'
import { useMobile } from './utils/ScreenWidthSize'
import Loading from './components/Loading'
import LoginUserLayout from './components/LoginUserLayout'
import VisitorIndex from './components/pages/visitor/Index'
import UserIndex from './components/pages/user/Index'
import UserProfile from './components/pages/user/Profile'
import BookingPage from './components/pages/user/operation/BookingPage'
import BookingInput from './components/pages/user/operation/BookingInput'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Routes,
  Route
} from 'react-router-dom';
import VisitorError from './components/pages/visitor/Error';
import UserManagementList from './components/pages/user/management/UserManagementList';
import UserManagementDetail from './components/pages/user/management/UserManagementDetail';


function App() {
  const {
    error,
    isLoading,
    isAuthenticated,
  } = useAuth0()

  if (error) {
    console.log("Oops... " + error.message)
  }

  return (
    <AppContext.Provider value={{
      'state': {
        'isMobile': useMobile()
      }
    }}>
    { isLoading ? <Loading/> : (
      <Routes>
      {isAuthenticated ? 
        (
          <Route path="/" element={<LoginUserLayout />}>
            <Route path="/" element={<UserIndex />} />
            <Route path="/operations/booking/:tab" element={<BookingPage />} />
            <Route path="/operations/booking/:tab/:id" element={<BookingInput />} />
            <Route path="/managements/user/:account" element={<UserManagementList />} />
            <Route path="/managements/user/:account/:id" element={<UserManagementDetail />} />
            <Route path="/user/profile" element={<UserProfile />} />
          </Route>
        ) : 
        (
          <Route>
            <Route path="/" element={<VisitorIndex />} />
            <Route path="*" element={<VisitorError />} />
          </Route>
        )
      }
      </Routes>
    )}
    </AppContext.Provider>
  )
}

export default App;
