import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react'
import React from 'react'

export interface Props {
  labelText: string
  labelWidth?: string
  labelPosition?: string
  children?: JSX.Element
}

const TextWithLabel = ({ 
  labelText,
  labelWidth,
  labelPosition = "left",
  children
}: Props) => {


  if (labelPosition === "top") {
    return (
      <Box my={"6px"} >
        <Box fontSize={"xs"} color={"gray.500"} alignItems={"baseline"}>
          <Text verticalAlign={'middle'}>{labelText}</Text>
        </Box>
        <Box flex={1} fontSize={"sm"} fontWeight={500}>{children}</Box>
      </Box>
    )
  }

  return (
    <Flex my={"6px"}>
      <Box fontSize={"xs"} w={labelWidth} color={"gray.600"}>{labelText}:</Box>
      <Box flex={1} overflow={"hidden"} fontWeight={500} fontSize={'sm'}>{children}</Box>
    </Flex>
  )
}

export default TextWithLabel
