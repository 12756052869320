import { FormControl, FormLabel, Input, InputGroup } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { Port } from '../../utils/types';

export interface Props {
  label: string
  value: string
  onChangeValue:(data: Port) => void
}

function LocationCodeInputField({
  label,
  value,
  onChangeValue,
}: Props) {
  const fontSize = "sm"
  const marginBottom = "12px"

  const getCountry = async (countryCode: string) => {
    const url = "https://harbitt-box-dev.s3.ap-northeast-1.amazonaws.com/unlocode/country-codes.json"
    const data = await (await fetch(url)).json();
    return data[countryCode];
  }

  const getCity = async (code: string) => {
    const url = "https://harbitt-box-dev.s3.ap-northeast-1.amazonaws.com/unlocode/" + code.substring(0, 3) + ".json"
    const data = await (await fetch(url)).json();
    return data[code];
  }

  const handleChangeValue = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { code: event.currentTarget.value.toUpperCase(), country:"", city:"" };

    if (data.code.length === 5) {
      data.country = await getCountry(data.code.substring(0, 2));
      data.city = await getCity(data.code);
    }
    onChangeValue(data);
  },[onChangeValue])

  return (
    <FormControl isRequired mb={marginBottom}>
      <FormLabel fontSize={fontSize}>{label}</FormLabel>
      <InputGroup>
        <Input size={"sm"} type='text' value={value} onChange={handleChangeValue}/>
      </InputGroup>
    </FormControl>
  )
}

export default LocationCodeInputField
