import {
  Text,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  SimpleGrid,
  Button,
  Grid,
  GridItem,
  Stack,
  Avatar,
  HStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Booking from '../../utils/Booking';
import useDateConverter from '../../utils/DateConverter';
import { BookingRegistration } from '../../utils/types';
import FlatCard from '../atoms/FlatCard';
import TextWithLabel from '../atoms/TextWithLabel';
import BookingInputBookingStatus from './BookingInputBookingStatus';
import BookingInputRegistrationStatus from './BookingInputRegistrationStatus';
import { UserApp } from '../../hooks/useUserApp';

export interface Props {
  tab: string;
  bookingInput: Booking;
  setBookingInput: (data: Booking) => void;
  bookingRegistrationInput?: BookingRegistration;
  userApp: UserApp | null;
  setBookingRegistrationInput?: (data: BookingRegistration) => void;
}

function BookingInputHeader({
  tab,
  bookingInput,
  setBookingInput,
  bookingRegistrationInput,
  userApp,
  setBookingRegistrationInput,
}: Props) {
  const navigate = useNavigate();
  const { ToLocalYearDateTime } = useDateConverter();

  return (
    <Box>
      <Breadcrumb fontSize={'xs'} m={'12px'}>
        <BreadcrumbItem>
          <Button
            textDecoration={'underline'}
            size={'xs'}
            variant={'link'}
            onClick={() => {
              navigate('/operations/booking/' + tab);
            }}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </Button>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <Text>Booking Detail</Text>
        </BreadcrumbItem>
      </Breadcrumb>

      <Box mb={'12px'} mt={'24px'}>
        {tab !== 'request' ? (
          <BookingInputBookingStatus
            bookingInput={bookingInput}
            setBookingInput={setBookingInput}
          />
        ) : typeof bookingRegistrationInput !== 'undefined' &&
          typeof setBookingRegistrationInput !== 'undefined' ? (
          <BookingInputRegistrationStatus
            bookingRegistrationInput={bookingRegistrationInput}
            setBookingRegistrationInput={setBookingRegistrationInput}
          />
        ) : (
          <></>
        )}
      </Box>

      <Grid templateColumns='repeat(3, 1fr)' gap='2'>
        <GridItem colSpan={2}>
          <FlatCard height='100%'>
            <SimpleGrid columns={4} spacing={10}>
              <TextWithLabel labelText='Booking No' labelPosition='top'>
                <Box>{bookingInput.data.carrierBookingId}</Box>
              </TextWithLabel>

              <TextWithLabel labelText='Carrier Code' labelPosition='top'>
                <Box>{bookingInput.data.carrierCode}</Box>
              </TextWithLabel>

              <TextWithLabel labelText='Requested Time' labelPosition='top'>
                <Box>{ToLocalYearDateTime(bookingInput.data.createdAt)}</Box>
              </TextWithLabel>

              <TextWithLabel labelText='User Status' labelPosition='top'>
                <Box>{bookingInput.data.status}</Box>
              </TextWithLabel>
            </SimpleGrid>
          </FlatCard>
        </GridItem>
        {userApp ? (
          <GridItem colSpan={1}>
            <FlatCard height='100%'>
              <HStack alignItems='start'>
                <Avatar src={userApp.picture} />
                <Box fontSize='sm'>
                  <Wrap>
                    <WrapItem>
                      <Text fontWeight='bold'>
                        {userApp.userMetadata?.organization}
                      </Text>
                    </WrapItem>
                    <WrapItem>
                      <Text fontWeight='bold'>{userApp.name}</Text>
                    </WrapItem>
                    <WrapItem color='blue.500'>
                      <Link to={`/managements/user/app/${userApp.id}`}>
                        詳細
                      </Link>
                    </WrapItem>
                  </Wrap>
                  <Wrap mt='1.5'>
                    <WrapItem>
                      <Text color='gray.500'>Email</Text>
                    </WrapItem>
                    <WrapItem>
                      <Text>{userApp.email}</Text>
                    </WrapItem>
                    <WrapItem>
                      <Text color='gray.500'>Tel</Text>
                    </WrapItem>
                    <WrapItem>
                      <Text>{userApp.userMetadata?.phone}</Text>
                    </WrapItem>
                  </Wrap>
                  <Stack mt='3' spacing='0.5'>
                    <Text color='gray.500'>オペレーターメモ</Text>
                    <Text>{userApp.appMetadata?.note}</Text>
                  </Stack>
                </Box>
              </HStack>
            </FlatCard>
          </GridItem>
        ) : null}
      </Grid>
    </Box>
  );
}

export default BookingInputHeader;
