import {
  Button, Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer,
} from '@chakra-ui/react'
import React from 'react'

export interface Props {
  isOpen: boolean
  onClose: any
  isLoading: boolean
  title: string
  labelOkButton?: string
  labelCancelButton?: string
  onClickOk: () => void
  children?: JSX.Element
  body?: JSX.Element
}

const ModalWithOkCancelButton = ({
  isOpen, 
  onClose,
  isLoading,
  title,
  labelOkButton,
  labelCancelButton,
  onClickOk,
  children,
  body,
}: Props) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={'normal'} fontSize='md'>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={3} pb={5}>
          <Box m={3}>{children || body}</Box>
        </ModalBody>

        <ModalFooter p={3} pt={5}>
          <Spacer/>
          <Button variant='ghost' size="sm" fontWeight={'normal'} onClick={onClose} disabled={isLoading}>
            {labelCancelButton ? labelCancelButton : "Cancel"}
          </Button>
          <Spacer/>
          <Button variant='ghost' fontWeight={'normal'} colorScheme='cyan' size="sm" onClick={() => {onClickOk()}} disabled={isLoading}>
            {labelOkButton ? labelOkButton : "OK"}
          </Button>
          <Spacer/>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalWithOkCancelButton
