import {
  Box,
  Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react'
import React from 'react'

export type tableColumnDef = {
  name: string
  header: JSX.Element
  cell: (row: any) => JSX.Element
  width?: string
  showOnHover: boolean
}

export interface Props {
  tableData: any[]
  tableColumn: (any & tableColumnDef)[]
  onClickRow?: (row: any) => void
  padding?: string
  headerBgColor?: string
  fixedHeader?: boolean
  height?: any
}

const ListTableWithFloatButton = ({
  tableData,
  tableColumn,
  onClickRow,
  padding,
  headerBgColor,
  fixedHeader = false,
  height,
}: Props) => {


  return (
    <Box height={height} overflowX={"hidden"} overflowY={height !== undefined ? "auto" : "hidden"}>
    <Table>
      <Thead style={ fixedHeader ? {
          "position": "sticky",
          "top": "0px",
          "zIndex": 2
        } : {}}>
        <Tr bg={headerBgColor}>
          {tableColumn.map( (v, k) => (
            <Th key={k} p={padding}>
              <>{v.header}</>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {tableData.map( (row, id) => (
          <Tr
            _hover={ {'backgroundColor' : '#F7FAFC'} }
            key={'row-' + id}
            position={"relative"}
            onPointerEnter={(e: any) => {
              let elements: Element[] = Array.from(document.getElementsByClassName("show-on-hover-" + id))
              elements.forEach( (e: Element) => {
                e.removeAttribute('hidden')
              })
            }}
            onPointerLeave={(e: any) => {
              let elements: Element[] = Array.from(document.getElementsByClassName("show-on-hover-" + id))
              elements.forEach( (e: Element) => {
                e.setAttribute("hidden", "")
              })
            }}
          >
            {tableColumn.map( (col) => (
              <Td
              width={col.width}
              key={id + "-" + col.name}
              p={col.showOnHover ? 0 : padding}
              onClick={onClickRow ? () => {onClickRow(row)} : ()=>{}}
              >
                <Box className={col.showOnHover ? "show-on-hover-" + id : ""} hidden={col.showOnHover}>{col.cell(row)}</Box>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
    </Box>
  )
}

export default ListTableWithFloatButton
