import { Box, Grid, GridItem } from '@chakra-ui/react'
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@chakra-ui/react'

const VisitorIndex = () => {
  const {
    loginWithRedirect
  } = useAuth0()
  return (
    <Grid
      templateAreas={`"header"
                      "content"`}
      gridTemplateRows={'66px 1fr'}
    >
      <GridItem area={'header'} boxShadow={'base'}>
        header
      </GridItem>

      <GridItem area={'content'}>
        <Box className='content-fill' overflowY="scroll" bg="blackAlpha.50">
        <Button onClick={loginWithRedirect}>Login</Button>
        </Box>
      </GridItem>
    </Grid>
  )
}

export default VisitorIndex
