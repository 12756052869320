import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../utils/fetch';
import { BookingInfo as BookingInfoType, BookingRegistration as BookingRegistrationType} from '../../../../utils/types';
import BookingList from '../../../organisms/BookingList';

const BookingPage = () => {
  let { tab } = useParams()
  const { fetchGet } = useFetch()
  const [registeredBookingList, setRegisteredBookingList] = useState<Array<BookingRegistrationType>|undefined>(undefined)
  const [bookingList, setBookingList] = useState<Array<BookingInfoType>|undefined>(undefined)

  const { getAccessTokenSilently } = useAuth0()

  const GetRegisteredBookings = async () => {
    setRegisteredBookingList(undefined)

    fetchGet(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/registrations/?status=waiting,suspended,invalid&order=ASC',
      (data) => {
        setRegisteredBookingList(Object.values(data))
      },
      (error) => {
        // toast(errorToast)
      }
    )
  }

  const GetBookings = async (v: string) => {
    setBookingList(undefined)
    fetchGet(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/bookings/?operation_status=' + v,
      (data) => {
        setBookingList(Object.values(data))
      },
      (error) => {
        // toast(errorToast)
      }
    )
  }

  useEffect(() => {
    if (tab === undefined || tab === "request") {
      setBookingList(undefined)
      GetRegisteredBookings()
    } else {
      setRegisteredBookingList(undefined)
      GetBookings(tab)
    }
  }, [tab]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <BookingList
      tableData={registeredBookingList || bookingList}
      onReload={GetRegisteredBookings}
      selectedTab={tab || "request"}
    />
  )
}

export default BookingPage
