// import Link from 'next/link'
import {
  Flex,
  Box,
  Heading,
  Spacer,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  IconButton
} from '@chakra-ui/react'
import { RiMenuFill } from 'react-icons/ri'
import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { AppContext } from '../utils/context';

const Header = ({ toggleMenu }: any) => {
  const appContext = useContext(AppContext)
  const {
    logout,
  } = useAuth0();

  return (
    <Box boxShadow='sm'>
      <Flex minWidth='max-content' alignItems='center' gap='6' bg="gray.200" className='header-fill'>
      <Box p='3' pl='3' pr='0'>
        { appContext.state.isMobile ? (
            <IconButton
              variant='ghost'
              color='blue.800'
              aria-label='Call Sage'
              fontSize='20px'
              _hover={{ bg: "blackAlpha.50" }}
              _focus={{ bg: "blackAlpha.50" }}
              icon={<RiMenuFill />}
              onClick={() => {toggleMenu()}}
            />
          ) : 
          <></>
        }
        </Box>
        <Box p='3' pl='0'>
        <Link to="/">
          <Heading size='lg' className='cursor-pointer' color="blue.800">
            <i>Harbitt <Box fontSize="sm" display={"inline-block"}>&lt;BackOffice&gt;</Box></i>
          </Heading>
        </Link>
        </Box>
        <Spacer />

        <Box pr='4' pt='3' pb='3'>
          <Menu>
            <MenuButton as={Avatar} size='sm' className='cursor-pointer'></MenuButton>
            <MenuList>
              <Link to="/user/profile"><MenuItem>Profile</MenuItem></Link>
              <MenuDivider />
              <MenuItem onClick={() => {
                logout({
                  returnTo: process.env.REACT_APP_URL || ""
                })
              }}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Box>
  )
}
  
export default Header
