import { Text, Box, CloseButton, Divider, Flex, FormControl, FormLabel, Input, Select, SimpleGrid, Spacer, Center, Button } from '@chakra-ui/react';
import React from 'react';
// @ts-ignore 
import Booking from '../../utils/Booking'; 
import { RequestedShipment, ScheduleChangeReason } from '../../utils/types';
import DateTimePicker from '../atoms/DateTimePicker';
import LocationCodeInputField from './LocationCodeInputField';
import moment from 'moment'
import useDateConverter from '../../utils/DateConverter';

export interface Props {
  bookingInput: Booking
  setBookingInput: (data: Booking) => void
}

function BookingInputTransportPlan({ bookingInput, setBookingInput }: Props) {
  const fontSize = "sm"
  const marginBottom = "12px"
  const {
    IgnoreTimeZone,
  } = useDateConverter()

  const addInputTransportPlan = () => {
    bookingInput.addTransportPlan()
    setBookingInput(new Booking(bookingInput.data))
  }

  const removeInputTransportPlan = (index: number)=>{
    bookingInput.deleteTransportPlan(index)
    setBookingInput(new Booking(bookingInput.data))
  }

  const handleChangeTransportPlan = (index: number, data: (RequestedShipment & ScheduleChangeReason))=>{
    bookingInput.updateTransportPlan(index, data)
    setBookingInput(new Booking(bookingInput.data))
  }

  const movePlan = (fromIndex: number, toIndex: number) => {
    bookingInput.moveTransportPlan(fromIndex, toIndex)
    setBookingInput(new Booking(bookingInput.data))
  }

  return (
    <>
    { bookingInput.data.requestedShipments?.map((data: (RequestedShipment & ScheduleChangeReason), index: number) => {
      return (
        <Box p={"12px"} key={index}>
            <Flex>
              <Text fontSize={"sm"} color={"gray.400"}>Transport No. {index + 1}</Text>
              <Spacer/>
              <Button m={"3px"} size={'xs'} onClick={(e) => {movePlan(index, index-1)}}>↑</Button>
              <Button m={"3px"} size={'xs'} onClick={(e) => {movePlan(index, index+1)}}>↓</Button>
              <Divider mx={"6px"} orientation='vertical'/>
              <CloseButton size={"sm"} onClick={() => {removeInputTransportPlan(index)}}/>
            </Flex>
            <Divider/>
            <Box p={"12px"} key={index}>

              <SimpleGrid columns={4} spacing={10}>
                <LocationCodeInputField
                  label={"From_LocationCode"}
                  value={data.departurePort.code}
                  onChangeValue={(port) => handleChangeTransportPlan(index, {...data, departurePort:port})}
                />

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>ETD</FormLabel>
                  <DateTimePicker
                    selectedDateTime={data.plannedDepartureTime ? new Date(IgnoreTimeZone(data.plannedDepartureTime)) : null}
                    onChange={(date: Date | null) => {
                      const d = {...data}
                      if (date === null) {
                        d.plannedDepartureTime = null
                      } else {
                        d.plannedDepartureTime = IgnoreTimeZone(moment(date).format()) + ":00Z"
                      }
                      handleChangeTransportPlan(index, d)
                    }}
                  />
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Reason of Update</FormLabel>
                  <Input
                    size={"sm"}
                    type='text'
                    value={data.plannedDepartureChangeReason || ""}
                    onChange={(event) => {
                      const d = {...data}
                      d.plannedDepartureChangeReason = (event.target.value.length === 0) ? null : event.target.value
                      handleChangeTransportPlan(index, d)
                    }}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={4} spacing={10}>
                <Spacer/>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>ATD</FormLabel>
                  <DateTimePicker
                    selectedDateTime={data.actualDepartureTime ? new Date(IgnoreTimeZone(data.actualDepartureTime)) : null}
                    onChange={(date: Date | null) => {
                      const d = {...data}
                      if (date === null) {
                        d.actualDepartureTime = null
                      } else {
                        d.actualDepartureTime = IgnoreTimeZone(moment(date).format()) + ":00Z"
                      }
                      handleChangeTransportPlan(index, d)
                    }}
                  />
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Reason of Update</FormLabel>
                  <Input
                    size={"sm"}
                    type='text'
                    value={data.actualDepartureChangeReason || ""}
                    onChange={(event) => {
                      const d = {...data}
                      d.actualDepartureChangeReason = (event.target.value.length === 0) ? null : event.target.value
                      handleChangeTransportPlan(index, d)
                    }}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={4} spacing={10}>
                <LocationCodeInputField
                  label={"To_LocationCode"}
                  value={data.arrivalPort.code}
                  onChangeValue={(port) => handleChangeTransportPlan(index, {...data, arrivalPort:port})}
                />

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>ETA</FormLabel>
                  <DateTimePicker
                    selectedDateTime={ data.plannedArrivalTime ? new Date(IgnoreTimeZone(data.plannedArrivalTime)) : null}
                    onChange={(date: Date | null) => {
                      const d = {...data}
                      if (date === null) {
                        d.plannedArrivalTime = null
                      } else {
                        d.plannedArrivalTime = IgnoreTimeZone(moment(date).format()) + ":00Z"
                      }
                      handleChangeTransportPlan(index, d)
                    }}
                  />
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Reason of Update</FormLabel>
                  <Input
                    size={"sm"}
                    type='text'
                    value={data.plannedArrivalChangeReason || ""}
                    onChange={(event) => {
                      const d = {...data}
                      d.plannedArrivalChangeReason = (event.target.value.length === 0) ? null : event.target.value
                      handleChangeTransportPlan(index, d)
                    }}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={4} spacing={10}>
                <Spacer/>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>ATA</FormLabel>
                  <DateTimePicker
                    selectedDateTime={ data.actualArrivalTime ? new Date(IgnoreTimeZone(data.actualArrivalTime)) : null}
                    onChange={(date: Date | null) => {
                      const d = {...data}
                      if (date === null) {
                        d.actualArrivalTime = null
                      } else {
                        d.actualArrivalTime = IgnoreTimeZone(moment(date).format()) + ":00Z"
                      }
                      handleChangeTransportPlan(index, d)
                    }}
                  />
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Reason of Update</FormLabel>
                  <Input
                    size={"sm"}
                    type='text'
                    value={data.actualArrivalChangeReason || ""}
                    onChange={(event) => {
                      const d = {...data}
                      d.actualArrivalChangeReason = (event.target.value.length === 0) ? null : event.target.value
                      handleChangeTransportPlan(index, d)
                    }}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={4} spacing={10}>
                <FormControl isRequired mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Transport Type</FormLabel>
                  <Select size={"sm"} placeholder='Select transport type' value={data.transportType === null ? 'ocean' : data.transportType} onChange={(event: any) => {
                      const d = {...data}
                      d.transportType = event.target.value === 'ocean' ? null : event.target.value
                      d.isOtherMethod = event.target.value === 'ocean' ? false : true
                      handleChangeTransportPlan(index, d)
                      }}>
                    <option value='ocean'>Ocean</option>
                    <option value='water'>Water</option>
                    <option value='truck'>Truck</option>
                    <option value='rail'>Rail</option>
                  </Select>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={4} spacing={10}>
                {data.transportType === null ? <>
                <FormControl isRequired mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Vessel Name</FormLabel>
                  <Input size={"sm"} type='text' value={data.vesselName || ""} onChange={(event: any) => {
                      const d = {...data}
                      d.vesselName = event.target.value.length === 0 ? null : event.target.value
                      handleChangeTransportPlan(index, d)
                      }}/>
                </FormControl>

                <FormControl isRequired mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Voyage No</FormLabel>
                  <Input size={"sm"} type='text' value={data.voyageId || ""} onChange={(event: any) => {
                      const d = {...data}
                      d.voyageId = event.target.value.length === 0 ? null : event.target.value
                      handleChangeTransportPlan(index, d)
                      }}/>
                </FormControl>

                <FormControl isRequired mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Service ID</FormLabel>
                  <Input size={"sm"} type='text' value={data.serviceCode || ""} onChange={(event: any) => {
                      const d = {...data}
                      d.serviceCode = event.target.value.length === 0 ? null : event.target.value
                      handleChangeTransportPlan(index, d)
                      }}/>
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Carrier Name</FormLabel>
                  <Input size={"sm"} type='text' value={data.vesselCarrier || ""} onChange={(event: any) => {
                      const d = {...data}
                      d.vesselCarrier = event.target.value.length === 0 ? null : event.target.value
                      handleChangeTransportPlan(index, d)
                      }}/>
                </FormControl>
                </>
                :
                <></>
                }
              </SimpleGrid>
            </Box>
        </Box>
      )
    })}

    <Center>
      <Button
        size={"xs"}
        colorScheme="cyan"
        variant={"ghost"}
        onClick={addInputTransportPlan}
        m={5}
      >
        ＋ Add Transport</Button>
    </Center>
    </>
  )
}

export default BookingInputTransportPlan
