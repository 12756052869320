import { Box, Text, FormControl, FormLabel, Input, SimpleGrid, Flex, Spacer, CloseButton, Center, Button, Checkbox, Divider, Select } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
// @ts-ignore 
import Booking from '../../utils/Booking';
import useDateConverter from '../../utils/DateConverter';
import { TransportEquipment } from '../../utils/types';
import DateTimePicker from '../atoms/DateTimePicker';
import FlatCard from '../atoms/FlatCard';

const containerTypes = new Map([
  ["20GP", "20 Dry Standard"],
  ["40GP", "40 Dry Standard"],
  ["40HQ", "40 Dry High Cube"],
  ["45HQ", "45 Dry High Cube"],
  ["20RF", "20 Reefer Standard"],
  ["40RF", "40 Reefer Standard"],
  ["40RQ", "40 Reefer High Cube"],
  ["20OT", "20 Open Top"],
  ["40OT", "40 Open Top"],
  ["40OTHQ", "40 Open Top High Cube"],
  ["20FR", "20 Flat Rack Standard"],
  ["40FR", "40 Flat Rack Standard"],
  ["40FRHQ", "40 Flat Rack High Cube"],
  ["20TK", "20 Tank"],
  ["40TK", "40 Tank"],
])
export interface Props {
  bookingInput: Booking
  setBookingInput: (data: Booking) => void
}

function BookingInputEquipment({bookingInput, setBookingInput }: Props) {
  const fontSize = "sm"
  const marginBottom = "12px"

  const {
    IgnoreTimeZone,
  } = useDateConverter()

  const addInputEquipment = () => {
    bookingInput.addEquipment()
    setBookingInput(new Booking(bookingInput.data))
  }

  const removeInputEquipment = (index: number)=>{
    bookingInput.deleteEquipment(index)
    setBookingInput(new Booking(bookingInput.data))
  }

  const handleChangeEquipment = (index: number, data: TransportEquipment)=>{
    bookingInput.updateEquipment(index, data)
    setBookingInput(new Booking(bookingInput.data))
  }

  let itemList: any = []
  containerTypes.forEach((value, key)=>{
    itemList.push( 
      <option key={key} value={key}>{value}</option>
    )
  })

  return (
    <>
    { bookingInput.data.transportEquipments?.map( (data: TransportEquipment, index: number ) => {

      return (
        <Box p={"12px"} key={index}>
          <FlatCard>
            <Flex>
              <Text fontSize={"sm"} color={"gray.400"}>Container No. {index + 1}</Text>
              <Spacer/>
              <CloseButton size={"sm"} onClick={() => {removeInputEquipment(index)}}/>
            </Flex>
            <Divider/>
            <Box p={"12px"} key={index}>
              <SimpleGrid columns={3} spacing={10}>
                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Container No</FormLabel>
                  <Input size={"sm"} type='text' value={data.reference || ""} onChange={(event: any) => {
                      data.reference = event.target.value.length === 0 ? null : event.target.value
                      handleChangeEquipment(index, data)
                      }}/>
                </FormControl>

                <FormControl isRequired mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Container Type</FormLabel>
                  <Select
                    placeholder='Select Container Type'
                    size='sm'
                    value={data.isoCode || ""}
                    onChange={(e) => {
                      data.isoCode = e.target.value.length === 0 ? null : e.target.value
                      handleChangeEquipment(index, data)
                    }}
                  >
                    {itemList}
                  </Select>
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Seal No</FormLabel>
                  <Input size={"sm"} type='text' value={data.sealId || ""} onChange={(event: any) => {
                      data.sealId = event.target.value.length === 0 ? null : event.target.value
                      handleChangeEquipment(index, data)
                      }}/>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={3} spacing={10}>
                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>SOC</FormLabel>
                  <Checkbox isChecked={data.isShipperOwn} onChange={(event: any) => {
                      data.isShipperOwn = !data.isShipperOwn
                      handleChangeEquipment(index, data)
                      }}/>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={3} spacing={10}>
                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Empty Pick Up DateTime</FormLabel>
                  <DateTimePicker
                    selectedDateTime={data.pickUpAt ? new Date(IgnoreTimeZone(data.pickUpAt)) : null}
                    onChange={(date: Date | null) => {
                      if (date === null) {
                        data.pickUpAt = null
                      } else {
                        data.pickUpAt = IgnoreTimeZone(moment(date).format()) + ":00Z"
                      }
                      handleChangeEquipment(index, data)
                    }}
                  />
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>CY Receipt DateTime</FormLabel>
                  <DateTimePicker
                    selectedDateTime={ data.receiptAt ? new Date(IgnoreTimeZone(data.receiptAt)) : null}
                    onChange={(date: Date | null) => {
                      if (date === null) {
                        data.receiptAt = null
                      } else {
                        data.receiptAt = IgnoreTimeZone(moment(date).format()) + ":00Z"
                      }
                      handleChangeEquipment(index, data)
                    }}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns={3} spacing={10}>
                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>CY Release DateTime</FormLabel>
                  <DateTimePicker
                    selectedDateTime={ data.releaseAt ? new Date(IgnoreTimeZone(data.releaseAt)) : null}
                    onChange={(date: Date | null) => {
                      if (date === null) {
                        data.releaseAt = null
                      } else {
                        data.releaseAt = IgnoreTimeZone(moment(date).format()) + ":00Z"
                      }
                      handleChangeEquipment(index, data)
                    }}
                  />
                </FormControl>

                <FormControl mb={marginBottom}>
                  <FormLabel fontSize={fontSize}>Empty Return DateTime</FormLabel>
                  <DateTimePicker
                    selectedDateTime={ data.returnAt ? new Date(IgnoreTimeZone(data.returnAt)) : null}
                    onChange={(date: Date | null) => {
                      if (date === null) {
                        data.returnAt = null
                      } else {
                        data.returnAt = IgnoreTimeZone(moment(date).format()) + ":00Z"
                      }
                      handleChangeEquipment(index, data)
                    }}
                  />
                </FormControl>
              </SimpleGrid>

            </Box>
            </FlatCard>
          </Box>
        )
      })}
      <Center>
        <Button
          size={"xs"}
          colorScheme="cyan"
          variant={"ghost"}
          onClick={addInputEquipment}
          m={5}
        >
          ＋ Add Equipment</Button>
      </Center>

    </>
  )
}

export default BookingInputEquipment
