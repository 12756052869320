import { Box, CloseButton, Flex, ToastId, UseToastOptions } from "@chakra-ui/react"
import React from "react"
import AccentLinkButton from "../components/atoms/AccentLinkButton"

function useToastOption() {

  const GetOption: (
    text: string,
    onClickSideButton?: (id: ToastId) => void,
    sideButtonLabel?: string,
    isLoading?: boolean
  ) => UseToastOptions = (
    text,
    onClickSideButton,
    sideButtonLabel,
    isLoading
  ) => {
    return {
      position: 'bottom-left',
      render: ({ onClose, id }) => (
        <Flex
          color='white'
          p={3}
          bg='gray.600'
          fontSize={'xs'}
          rounded={'sm'}
          boxShadow={'base'}
        >
        <Box flex={1}>
          {text}
        </Box>
        { onClickSideButton !== undefined ?
        <AccentLinkButton
          label={sideButtonLabel ? sideButtonLabel : 'Undo'}
          fontWeight={500}
          size={'xs'}
          isLoading={isLoading}
          onClick={() => {
            onClickSideButton(id)
          }}
        />
        :
        <></>
        }
        <CloseButton ml={"9px"} px={"3px"} size="xs" onClick={onClose} />
        </Flex>
      )
    }
  }

  return { GetOption }
}

export default useToastOption
