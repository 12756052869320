import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Box, Grid, GridItem, useDisclosure, Wrap } from '@chakra-ui/react'
import { useContext } from 'react'
import Header from './Header'
import SideMainMenu from './SideMainMenu'
import SideMainMenuDrawer from './SideManMenuDrawer';
import { AppContext } from '../utils/context';
import React from 'react';
import { Outlet } from 'react-router-dom';

const LoginUserLayout = () => {
  const appContext = useContext(AppContext)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isLoading,
    error,
  } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <Grid
      templateAreas={`"header header"
                      "nav content"`}
      gridTemplateRows={'66px 1fr'}
      gridTemplateColumns={ appContext.state.isMobile ? '0px 1fr' : '180px 1fr' }
    >
      <GridItem area={'header'} boxShadow={'base'}>
        <Header toggleMenu={() => {
          // setLoginMenuOpen(!menuOpen)
          // setMenuOpen(!menuOpen)
          onOpen()
        }}/>
      </GridItem>

      <GridItem area={'nav'} className='content-fill' bg='gray.200'>
        <Wrap className='content-fill'>
        { appContext.state.isMobile ? 
          <SideMainMenuDrawer menuOpen={isOpen} onClose={onClose}/> : 
          <SideMainMenu onClose={() => {}}/>
        }
        </Wrap>
      </GridItem>

      <GridItem area={'content'}>
        <Box className='content-fill' overflowY="scroll" bg="gray.50">
          <Outlet/>
        </Box>
      </GridItem>
    </Grid>
  )
}

export default withAuthenticationRequired(LoginUserLayout)
