import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../utils/fetch';
import { UserInfo } from '../../../../utils/types';
import UserDetail from '../../../organisms/UserDetail';

function UserManagementDetail() {
  const { fetchGet } = useFetch()
  const [userInfo, setUserInfo] = useState<UserInfo|undefined>(undefined)
  const { getAccessTokenSilently } = useAuth0()

  const { account, id } = useParams()

  const GetUserInfo = async () => {
    setUserInfo(undefined)
    fetchGet(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/users/' + ((account === "app") ? "app/" : "") + id,
      (data) => {
        setUserInfo(data as UserInfo)
      },
      (error) => {
        // toast(errorToast)
      }
    )
  }

  useEffect(() => {
    GetUserInfo()
  }, [account, id]) // eslint-disable-line react-hooks/exhaustive-deps

  if (account === undefined) {
    return <p>Invalid URL</p>
  }

  return (
    <UserDetail userInfo={userInfo} account={account}/>
  )
}

export default UserManagementDetail
