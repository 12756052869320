import { useCallback } from 'react';

function useFetch() {
  const getHeader = useCallback((token: string) => {
    return new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
  }, []);

  const fetchGet = useCallback(
    (
      accessToken: string,
      path: string,
      onSuccess: (res: Object) => void,
      onFailure: (res: Object) => void,
    ) => {
      fetch(path, { method: 'GET', headers: getHeader(accessToken) })
        .then((res) => {
          res.json().then((data) => {
            if (res.status === 200) {
              if (onSuccess !== undefined) {
                onSuccess(data);
              }
            } else {
              if (onFailure !== undefined) {
                onFailure(data);
              }
            }
          });
        })
        .catch((err) => {
          if (onFailure !== undefined) {
            onFailure({ error: err });
          }
        });
    },
    [getHeader],
  );

  const fetchPost = useCallback(
    (
      accessToken: string,
      path: string,
      body: Array<any> | Object,
      onSuccess: (res: Object) => void,
      onFailure: (res: Object) => void,
    ) => {
      fetch(path, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: getHeader(accessToken),
      })
        .then((res) => {
          res.json().then((data) => {
            if (res.status === 200) {
              if (onSuccess !== undefined) {
                onSuccess(data);
              }
            } else {
              if (onFailure !== undefined) {
                onFailure(data);
              }
            }
          });
        })
        .catch((err) => {
          if (onFailure !== undefined) {
            onFailure({ error: err });
          }
        });
    },
    [getHeader],
  );

  const fetchPut = useCallback(
    (
      accessToken: string,
      path: string,
      body: Array<any> | Object,
      onSuccess: (res: Object) => void,
      onFailure: (res: Object) => void,
    ) => {
      fetch(path, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: getHeader(accessToken),
      })
        .then((res) => {
          res.json().then((data) => {
            if (res.status === 200) {
              if (onSuccess !== undefined) {
                onSuccess(data);
              }
            } else {
              if (onFailure !== undefined) {
                onFailure(data);
              }
            }
          });
        })
        .catch((err) => {
          if (onFailure !== undefined) {
            onFailure({ error: err });
          }
        });
    },
    [getHeader],
  );

  const fetchDelete = useCallback(
    (
      accessToken: string,
      path: string,
      body: unknown[] | object,
      onSuccess: (res: object) => void,
      onFailure: (res: object) => void,
    ) => {
      fetch(path, {
        method: 'DELETE',
        body: JSON.stringify(body),
        headers: getHeader(accessToken),
      })
        .then((res) => {
          void res.json().then((data) => {
            if (res.status === 200) {
              if (onSuccess !== undefined) {
                onSuccess(data);
              }
            } else {
              if (onFailure !== undefined) {
                onFailure(data);
              }
            }
          });
        })
        .catch((err) => {
          if (onFailure !== undefined) {
            onFailure({ error: err });
          }
        });
    },
    [getHeader],
  );

  return { fetchGet, fetchPost, fetchPut, fetchDelete };
}

export default useFetch;
