import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@chakra-ui/react';
import React from 'react';

function Index() {
  const { user } = useAuth0()

  return (
    <Box p={5}>Welcome {user!.name}!</Box>
  )
}

export default Index
