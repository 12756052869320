import {
  Box,
  MenuItem,
} from '@chakra-ui/react'
import {
  RiCheckLine,
} from 'react-icons/ri'
import React from 'react'

export interface Props {
  text: string
  value: string
  isSelected: boolean
}

const SelectItemWithCheck = ({ text, value, isSelected }: Props) => {

  return (
    <MenuItem value={value} fontSize={"sm"}>
      {text}{isSelected ? <Box ml={3}><RiCheckLine/></Box>: <></>}
    </MenuItem>
  )
}

export default SelectItemWithCheck
