import {
  Button, HStack, Menu, MenuButton, MenuList, Text,
} from '@chakra-ui/react'
import {
  RiArrowDropDownLine,
} from 'react-icons/ri'
import React from 'react'
import SelectItemWithCheck from './SelectItemWithCheck'

export interface Props {
  label?: string
  options: Map<string, string>
  selectedValue: string
  onClick: (newSelectedValue: string) => void
  width?: string
  disabled?: boolean
}

const SelectWithLabel = ({
  label,
  options,
  selectedValue,
  onClick,
  width,
  disabled,
}: Props) => {

  let itemList: any = []
  options.forEach((displayText: string, value: string)=>{
    itemList.push( 
      <SelectItemWithCheck key={value} text={displayText} value={value} isSelected={value === selectedValue}/>
    )
  })

  return (
    <HStack>
      <Text fontSize='sm'>{label ? label + " :" : ""}</Text>
      <Menu>
        <MenuButton
          as={Button}
          size='sm'
          variant='ghost'
          rightIcon={<RiArrowDropDownLine/>}
          colorScheme='cyan'
          fontWeight={300}
          width={width}
          textAlign={"left"}
          disabled={disabled}
        >{options.get(selectedValue)}</MenuButton>
        <MenuList
          zIndex={9}
          onClick={(e: any) => {
            if (e.target.value !== undefined) {
              onClick(e.target.value)
            }
          }}
        >
          {itemList}
        </MenuList>
      </Menu>
    </HStack>
  )
}

export default SelectWithLabel
