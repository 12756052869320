import {
  List,
  ListItem,
  Box,
} from '@chakra-ui/react'
import React from 'react';
import { Link } from "react-router-dom";
import MenuItem from './atoms/MenuItem';

export interface Props {
  onClose: () => void
}

const SideMainMenu = ({ onClose }: Props) => {

  return (
    <List bg='gray.200'>
      <ListItem backgroundColor={"blackAlpha.100"} mb={3}>
        <Box pl={3} textColor={"blue.800"} fontWeight={500}>Operation</Box>
      </ListItem>
      <Link to="/operations/booking/request">
        <MenuItem label={"Booking"} onClose={onClose} />
      </Link>
      
      <ListItem backgroundColor={"blackAlpha.100"} mt={3} mb={3}>
        <Box pl={3} textColor={"blue.800"} fontWeight={500}>
          Management
        </Box>
      </ListItem>
      <Link to="/managements/user/app">
        <MenuItem label={"Harbitt User"} onClose={onClose} />
      </Link>
      <Link to="/managements/user/backoffice">
        <MenuItem label={"BackOffice User"} onClose={onClose} />
      </Link>
    </List>
  )
}

export default SideMainMenu
