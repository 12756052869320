import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Booking from '../../../../utils/Booking';
import useFetch from '../../../../utils/fetch';
import {
  BookingInfo as BookingInfoType,
  BookingRegistration as BookingRegistrationType,
} from '../../../../utils/types';
import Loading from '../../../Loading';
import BookingInputForm from '../../../organisms/BookingInputForm';

function BookingInput() {
  const [bookingInfo, setBookingInfo] = useState<Booking>(new Booking());
  const [bookingRegistrationInfo, setBookingRegistrationInfo] = useState<
    BookingRegistrationType | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { fetchGet } = useFetch();
  const { getAccessTokenSilently } = useAuth0();

  // Get id from url
  const { tab, id } = useParams();

  // Get requested booking info
  useEffect(() => {
    setIsLoading(true);
    if (tab === 'request') {
      (async () => {
        fetchGet(
          await getAccessTokenSilently(),
          process.env.REACT_APP_HARBITT_BACKEND_URL +
            '/backoffice/v1/registrations/' +
            id,
          (data) => {
            console.log(data);

            const registeredBooking = data as BookingRegistrationType;
            bookingInfo.loadFromRegistrationData(registeredBooking);
            setBookingInfo(bookingInfo);
            setBookingRegistrationInfo(registeredBooking);
            setIsLoading(false);
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
          },
        );
      })();
    } else {
      (async () => {
        fetchGet(
          await getAccessTokenSilently(),
          process.env.REACT_APP_HARBITT_BACKEND_URL +
            '/backoffice/v1/bookings/' +
            id +
            '?edge=shipment,equipment,commodity',
          (data) => {
            bookingInfo.loadFromBookingData(data as BookingInfoType);
            setBookingInfo(bookingInfo);
            setIsLoading(false);
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
          },
        );
      })();
    }
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? (
    <Loading />
  ) : (
    <BookingInputForm
      tab={tab || 'request'}
      bookingInfo={bookingInfo}
      bookingRegistrationInfo={bookingRegistrationInfo}
    />
  );
}

export default BookingInput;
