import {
  Heading,
  Divider,
  Stat,
  StatLabel,
  StatHelpText,
  Box,
  Button,
} from "@chakra-ui/react"
import React from 'react'
import { useState } from "react"
import FlatCard from "../atoms/FlatCard"
import jwt_decode from "jwt-decode"
import { useAuth0 } from '@auth0/auth0-react'

const UserDebugComponent = () => {
  const [isLoading, setLoading] = useState(true)
  const [token, setToken] = useState<string>("")
  const { getAccessTokenSilently } = useAuth0()

  const onClickTokenCopy = (token: string) => {
    navigator.clipboard.writeText("Bearer " + token)
      .then(() => {
        alert('Text copied to clipboard');
      })
      .catch(err => {
        alert('Error in copying text: ' + err);
      });
  }

  (async () => {
    try {
      const t = await getAccessTokenSilently()
      setToken(t)
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  })()

  if (isLoading) return <p>Loading...</p>

  return (
    <>
    <Divider />
    <Box m="12px">
      <Heading size="lg" m="12px">Debug window</Heading>
      <FlatCard>
        <Stat>
          <StatLabel>Auth0 token - <Button size="xs" onClick={() => {onClickTokenCopy(token)}}>CopyRaw</Button></StatLabel>
          <Divider mt="6px" mb="6px"/>
          <StatHelpText bgColor={'gray.100'}>
            <pre>{JSON.stringify(jwt_decode(token), null,  "  ")}</pre>
          </StatHelpText>
        </Stat>
      </FlatCard>
    </Box>
    </>
  )
}

export default UserDebugComponent
