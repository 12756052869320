import {
  Box, Button, IconButton, Text, useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import useDateConverter from '../../utils/DateConverter'
import { BookingScheduleHistoryResponse } from '../../utils/types'
import ListTableWithFloatButton from '../atoms/ListTableWithFloatButton'
import Loading from '../Loading'
import { 
  RiDeleteBinLine
} from "react-icons/ri"
import ModalWithOkCancelButton from '../atoms/ModalWithOkCancelButton'
import TextWithLabel from '../atoms/TextWithLabel'
import { useAuth0 } from '@auth0/auth0-react'
import useFetch from '../../utils/fetch'

export interface Props {
  historyData: BookingScheduleHistoryResponse[] | undefined
  setHistoryData: (data: BookingScheduleHistoryResponse[]) => void
  bookingId: string
}

const HistoryList = ({
  historyData,
  setHistoryData,
  bookingId
}: Props) => {

  const {
    ToYearDateTime,
    ToLocalYearDateTime,
  } = useDateConverter()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedSchedule, setSelectedSchedule] = useState<BookingScheduleHistoryResponse | undefined>(undefined)
  const [isSending, setIsSending] = useState(false)
  const { fetchDelete } = useFetch()
  const { getAccessTokenSilently } = useAuth0()

  const submitDelete = async (historyId: string, callback: () => void) => {
    setIsSending(true)
    fetchDelete(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/bookings/' + bookingId + '/histories',
      [historyId],
      (data) => {
        setIsSending(false)
        callback()
      },
      (error) => {
        setIsSending(false)
        console.log(error)
        // FIXME: Error handling
      }
    )
  }

  const columns = [
    {
      name: 'event-time',
      header: <Text>Event Time</Text>,
      cell: (row: BookingScheduleHistoryResponse) => {
        return <Text fontSize={'xs'}>{ToYearDateTime(row.datetime)}</Text>
      }
    },
    {
      name: 'status',
      header: <Text>Status</Text>,
      cell: (row: BookingScheduleHistoryResponse) => {
        return <Text fontSize={'xs'}>{row.datetimeType}</Text>
      }
    },
    {
      name: 'update-date',
      header: <Text>Update Date</Text>,
      cell: (row: BookingScheduleHistoryResponse) => {
        return <Text fontSize={'xs'}>{ToLocalYearDateTime(row.updatedAt)}</Text>
      }
    },
    {
      name: 'reason',
      header: <Text>Reason</Text>,
      cell: (row: BookingScheduleHistoryResponse) => {
        return <Text fontSize={'xs'}>{row.reason || "-"}</Text>
      }
    },
    {
      name: 'location',
      header: <Text>Location</Text>,
      cell: (row: BookingScheduleHistoryResponse) => {
        return <Text fontSize={'xs'}>{row.location.city}</Text>
      }
    },
    {
      name: 'button',
      header: <Text textAlign={'center'}></Text>,
      showOnHover: true,
      width: "40px",
      cell: (row: BookingScheduleHistoryResponse) => {
        return (
          <Box>
            <Button
            variant="ghost"
            size={"xs"}
            as={IconButton}
            icon={<RiDeleteBinLine />}
            onClick={() => {
              setSelectedSchedule(row)
              onOpen()
            }}/>
          </Box>
        )
      }
    }
  ]

  if (historyData === undefined) {
    return <Loading/>
  }

  return (
    <Box flex='1' bg="white">
      <ListTableWithFloatButton
        tableData={historyData}
        tableColumn={columns}
        padding='9px'
        headerBgColor='gray.100'
        fixedHeader={true}
        //height={appContext.state.isMobile ? "calc(100vh - 420px)" : "calc(100vh - 440px)"}
      />
      <ModalWithOkCancelButton
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isSending}
        title={'Conformation - Delete'}
        labelOkButton={'Delete'}
        onClickOk={() => {
          if (selectedSchedule === undefined || selectedSchedule === null) {
            return
          }
          if (selectedSchedule.id === undefined || selectedSchedule.id === null) {
            return
          }

          submitDelete(selectedSchedule.id, () => {
            const h = [...historyData]
            const index = h.findIndex((v) => {return v.id === selectedSchedule.id})
            if (index === -1) { return }
            h.splice(index, 1)
            setHistoryData(h)
            onClose()
          })

        }}
      >
        {selectedSchedule === undefined ?
        <></>
        :
        <>
          <TextWithLabel labelText={'Event Time'} labelPosition='top'>
            <>{ToYearDateTime(selectedSchedule?.datetime)}</>
          </TextWithLabel>
          <TextWithLabel labelText={'Status'} labelPosition='top'>
            <>{selectedSchedule?.datetimeType}</>
          </TextWithLabel>
          <TextWithLabel labelText={'Reason'} labelPosition='top'>
            <>{selectedSchedule?.reason || '-'}</>
          </TextWithLabel>
          <TextWithLabel labelText={'Location'} labelPosition='top'>
            <>{selectedSchedule?.location.city}</>
          </TextWithLabel>
          <TextWithLabel labelText={'Updated Time'} labelPosition='top'>
            <>{ToYearDateTime(selectedSchedule?.updatedAt)}</>
          </TextWithLabel>
        </>
        }
      </ModalWithOkCancelButton>
    </Box>
  )
}

export default HistoryList
