import {
  Text,
  Box,
  Tabs,
  Flex,
  TabList,
} from '@chakra-ui/react'
import React, { useContext } from 'react'
import type { BookingInfo, BookingRegistration } from '../../utils/types'
import ListTable from '../atoms/ListTable'
import { useNavigate } from 'react-router-dom'
import FilledTab from '../atoms/FilledTab'
import Loading from '../Loading'
import { AppContext } from '../../utils/context'
import useDateConverter from '../../utils/DateConverter'

export interface Props {
  tableData: BookingRegistration[] | BookingInfo[] | undefined
  onReload: () => void
  selectedTab: string
}

const BookingList = ({
  tableData,
  onReload,
  selectedTab,
}: Props) => {
  const navigate = useNavigate()
  const appContext = useContext(AppContext)
  const {
    ToLocalYearDateTime
  } = useDateConverter()

  const onClickTab = (status: string) => {
    navigate("/operations/booking/" + status)
  }

  const onClickRow = (row: BookingRegistration | BookingInfo) => {
    navigate("/operations/booking/" + selectedTab + "/" + row.id)
  }

  const columns = [
    {
      name: 'carrier-booking-id',
      header: <Text textAlign={'center'}>Booking No.</Text>,
      cell: (row: BookingRegistration | BookingInfo) => {
        return <Text textAlign={'center'} fontSize={'sm'}>
          {row.carrierBookingId}
        </Text>
      }
    },
    {
      name: 'carrier-code',
      header: <Text textAlign={'center'}>Carrier</Text>,
      cell: (row: BookingRegistration | BookingInfo) => {
        return <Text textAlign={'center'} fontSize={'sm'}>
          {row.carrierCode}
        </Text>
      }
    },
    {
      name: 'status',
      header: <Text textAlign={'center'}>Status</Text>,
      cell: (row: BookingRegistration | BookingInfo) => {
        return <Text textAlign={'center'} fontSize={'sm'}>
          {row.status}
        </Text>
      }
    },
    {
      name: 'created-at',
      header: <Text textAlign={'center'}>CreatedTime</Text>,
      cell: (row: BookingRegistration | BookingInfo) => {
        return <Text textAlign={'center'} fontSize={'sm'}>
          {ToLocalYearDateTime(row.createdAt)}
        </Text>
      }
    },
    {
      name: 'last-updatetime',
      header: <Text textAlign={'center'}>Last UpdateTime</Text>,
      cell: (row: BookingRegistration | BookingInfo) => {
        return <Text textAlign={'center'} fontSize={'sm'}>
          {ToLocalYearDateTime(row.updatedAt)}
        </Text>
      }
    }
  ]

  return (
    <Tabs variant='enclosed' pt={ appContext.state.isMobile ? "6px" : "28px" }>
      <Flex>
      <TabList mx={ appContext.state.isMobile ? "6px" : "21px"}>
        <FilledTab
        text={"Request"}
        value={"request"}
        onSelectedTab={onClickTab}
        selectedTab={selectedTab}
        />
        <FilledTab
        text={"Tracking"}
        value={"tracking"}
        onSelectedTab={onClickTab}
        selectedTab={selectedTab}
        />
        <FilledTab
        text={"Non-Tracking"}
        value={"non-tracking"}
        onSelectedTab={onClickTab}
        selectedTab={selectedTab}
        />
      </TabList>
      </Flex>
      <Box m={ appContext.state.isMobile ? "3px" : "15px"} mt={0} boxShadow="base" bg='white'>
        {tableData === undefined ? <Loading/> : 
        <Box overflowX={'auto'} pt={"46px"}>
          <ListTable
            tableData={tableData}
            tableColumn={columns}
            padding='12px'
            headerBgColor='gray.200'
            onClickRow={onClickRow}
          />
        </Box>
        }
      </Box>
    </Tabs>
  )
}

export default BookingList

