import { Box, Flex, Spacer, Text, useToast, UseToastOptions } from '@chakra-ui/react';
import React, { useState } from 'react';
import useFetch from '../../utils/fetch';
import { useAuth0 } from '@auth0/auth0-react';
import { BookingScheduleHistory } from '../../utils/types';
import PrimaryButton from '../atoms/PrimaryButton';
import useToastOption from '../../utils/ToastOptions';
// @ts-ignore 
import Booking from '../../utils/Booking';
import { useNavigate } from 'react-router-dom';
import useDateConverter from '../../utils/DateConverter';

export interface Props {
  tab: string
  bookingInfo: Booking
  bookingInput: Booking
}

function BookingInputButtonGroup({ tab, bookingInfo, bookingInput }: Props) {
  const { fetchPut, fetchPost } = useFetch()
  const { getAccessTokenSilently } = useAuth0()
  const [result, setResult] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [currentBookingInfo, setCurrentBookingInfo] = useState<Booking>(new Booking(bookingInfo.data))

  const {
    ToLocalYearDateTime
  } = useDateConverter()

  const toast = useToast()
  const { GetOption } = useToastOption()
  const navigate = useNavigate()

  const successToast = () => {
    const successToast: UseToastOptions = GetOption("Succeed to update booking")
    toast(successToast)
  }

  const errorToast = () => {
    const errorToast: UseToastOptions = GetOption("Failed to update booking")
    toast(errorToast)
  }

  const submitScheduleChange = async () => {
    const diff: Array<BookingScheduleHistory> = bookingInput.getScheduleChange(currentBookingInfo)
    fetchPost(
      await getAccessTokenSilently(),
      process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/bookings/' + bookingInput.data.id + "/histories",
      diff,
      (res: Object) => {
        setResult("")
        setIsSending(false)
        successToast()
        if (tab === 'request') {
          navigate('/operations/booking/' + tab)
        } else {
          setCurrentBookingInfo(new Booking(JSON.parse(JSON.stringify(bookingInput.data))))
        }
      },
      (res: Object) => {
        setResult(JSON.stringify(res))
        setIsSending(false)
        errorToast()
      }
    )
  }

  const onSubmit = async () => {
    const validation: string = bookingInput.validateRequest()
    if (validation.length !== 0) {
      setResult(validation)
      errorToast()
      return
    }

    setIsSending(true)
    if (tab === 'request') {
      fetchPost(
        await getAccessTokenSilently(),
        process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/registrations/' + bookingInput.data.id + "/booking",
        bookingInput.data,
        (res: Object) => {
          setResult("")
          submitScheduleChange()
        },
        (res: Object) => {
          setResult(JSON.stringify(res))
          setIsSending(false)
          errorToast()
        }
      )
    } else {
      fetchPut(
        await getAccessTokenSilently(),
        process.env.REACT_APP_HARBITT_BACKEND_URL + '/backoffice/v1/bookings/' + bookingInput.data.id,
        bookingInput.data,
        (res: Object) => {
          setResult("")
          submitScheduleChange()
        },
        (res: Object) => {
          setResult(JSON.stringify(res))
          setIsSending(false)
          errorToast()
        }
      )
    }
  }

  return (
    <Flex>
      <Box>
        <PrimaryButton
          label={tab === 'request' ? "Submit" : "Update"}
          size={"sm"}
          onClick={onSubmit}
          isLoading={isSending}
          disabled={isSending}
        />
        <Text color={"red"}>{result}</Text>
      </Box>
      <Spacer />
      <Box p={'12px'} fontSize={'sm'} color={'gray.500'}>
        Last Update: {ToLocalYearDateTime(bookingInput.data.updatedAt)}
      </Box>
    </Flex>
  )
}

export default BookingInputButtonGroup
