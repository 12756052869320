import { Box, FormControl, FormLabel, Input, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

import { UserInfo } from '../../utils/types';

export interface Props {
  userInput: UserInfo
  setUserInput: any
  disabled: boolean
  initPassword?: string
}

function UserInputForm({ userInput, setUserInput, disabled, initPassword }: Props) {
  const fontSize = "sm"
  const marginBottom = "12px"

  return (
    <Box p={"12px"} >
      <SimpleGrid columns={2} spacing={10} m={5}>
        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Family Name</FormLabel>
          <Input
            size={"sm"}
            type='text'
            value={userInput?.familyName || ""}
            disabled={disabled}
            onChange={(e: any) => {
              const data = {...userInput}
              data.familyName = e.target.value
              setUserInput(data)
            }}
          />
        </FormControl>

        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Given Name</FormLabel>
          <Input
            size={"sm"}
            type='text'
            value={userInput?.givenName || ""}
            disabled={disabled}
            onChange={(e: any) => {
              const data = {...userInput}
              data.givenName = e.target.value
              setUserInput(data)
            }}
          />
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={2} spacing={10} m={5}>
        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Email</FormLabel>
          <Input
            size={"sm"}
            type='text'
            value={userInput?.email ||  ""}
            disabled={disabled}
            onChange={(e: any) => {
              const data = {...userInput}
              data.email = e.target.value
              setUserInput(data)
            }}
          />
       </FormControl>

      { initPassword ? 
        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Initial Password</FormLabel>
          <Input size={"sm"} type='text' value={initPassword} disabled={true}/>
        </FormControl>
        : <></>
      }
      </SimpleGrid>

      <SimpleGrid columns={2} spacing={10} m={5}>
        <FormControl mb={marginBottom} isRequired>
          <FormLabel fontSize={fontSize}>Organization</FormLabel>
          <Input
            size={"sm"}
            type='text'
            value={userInput?.userMetadata?.organization || ""}
            disabled={disabled}
            onChange={(e: any) => {
              const data = {...userInput}
              if (data.userMetadata === null) {
                data.userMetadata = {
                  organization: e.target.value,
                  phone: "",
                }
              } else {
                data.userMetadata.organization = e.target.value
              }
              setUserInput(data)
            }}
          />
        </FormControl>

        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>Phone Number</FormLabel>
          <Input
            size={"sm"}
            type='text'
            value={userInput?.userMetadata?.phone || ""}
            disabled={disabled}
            onChange={(e: any) => {
              const data = {...userInput}
              if (data.userMetadata === null) {
                data.userMetadata = {
                  organization: "",
                  phone: e.target.value,
                }
              } else {
                data.userMetadata.phone = e.target.value
              }
              setUserInput(data)
            }}
          />
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={1} spacing={10} m={5}>
        <FormControl mb={marginBottom}>
          <FormLabel fontSize={fontSize}>Note</FormLabel>
          <Input
            size={"sm"}
            type='text'
            value={userInput?.appMetadata?.note || ""}
            disabled={disabled}
            onChange={(e: any) => {
              const data = {...userInput}
              if (data.appMetadata === null) {
                data.appMetadata = {
                  note: e.target.value,
                }
              } else {
                data.appMetadata.note = e.target.value
              }
              setUserInput(data)
            }}
          />
        </FormControl>
      </SimpleGrid>
    </Box>
  )
}

export default UserInputForm
