import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@chakra-ui/react'
import React, { useEffect } from 'react';

const VisitorError = () => {
  const {
    loginWithRedirect
  } = useAuth0()

  useEffect(() => {
    loginWithRedirect()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>Redirect...</Box>
  )
}

export default VisitorError
