import {
  Button, ButtonGroup,
} from '@chakra-ui/react'
import React from 'react'

export interface Props {
  options: Map<string, string>
  selectedValue: string
  onClick: (newSelectedValue: string) => void
  disabled?: boolean
}

const SelectButtonGroup = ({
  options,
  selectedValue,
  onClick,
  disabled,
}: Props) => {

  let itemList: any = []
  options.forEach((displayText: string, value: string)=>{
    itemList.push( 
      <Button
        key={value}
        isActive={selectedValue === value}
        onClick={() => {onClick(value)}}
      >
        {displayText}
      </Button>
    )
  })

  return (
    <ButtonGroup size='sm' isAttached variant='outline' defaultValue={selectedValue}>
      {itemList}
    </ButtonGroup>
  )
}

export default SelectButtonGroup
