import { Box } from '@chakra-ui/react';

export interface Props {
  height?: any;
  width?: string;
  overflowY?: any;
  children: any;
}

const FlatCard = ({ height, width, overflowY, children }: Props) => {
  return (
    <Box
      rounded={'base'}
      border='solid 1px'
      borderColor='gray.100'
      px='6'
      py='4'
      height={height}
      width={width}
      overflowY={overflowY}
      flex='1'
      bg='white'
    >
      {children}
    </Box>
  );
};

export default FlatCard;
