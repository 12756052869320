import {
  Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'

export interface Props {
  isOpen: boolean
  onClose: any
  title: string
  size?: string
  children: JSX.Element
}

const ModalPlain = ({
  isOpen, 
  onClose,
  title,
  size = "md",
  children,
}: Props) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={'normal'} fontSize='md'>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={3} pb={5}>
          <Box m={3}>{children}</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalPlain
